@import "./../../../index.module.scss";

.labelSwitch {
  @include flex-raw(space-between);
}

.switchLabel {
  padding-right: unit(16);
  @include font-14;

  color: var(--text-color-body);
}

.switchCabinet {
  @include font-14;
}

.toggleDisabled {
  cursor: not-allowed;

  :global {
    label {
      cursor: not-allowed;
    }
  }
}
