@import "../../../../../index.module.scss";

.infoSalePc {
  @include pcTabPageContainer;
}

.pageContent {
  @include pcTabPageContent;
}

.buttons {
  @include pcTabButtonContainer;
}

.contentContainer {
  @include pcPageViewContainer;

  padding: 0;
}
