@import "../../index.module.scss";

$cell-size: unit(50);
$cell-height: unit(72);
$gap: unit(12);

.upload {
  ---gap: var($gap);
  ---gap-horizontal: var(--gap-horizontal, var(--gap));
  ---gap-vertical: var(--gap-vertical, var(--gap));

  &-grid,
  &-space {
    --gap: var(---gap);
    --gap-horizontal: var(---gap-horizontal);
    --gap-vertical: var(---gap-vertical);

    display: grid;
    grid-gap: $gap;
    grid-template-columns: repeat(5, unit(50));
  }

  &-cell {
    position: relative;
    width: $cell-size;
    height: $cell-height;
    overflow: hidden;

    &-noContent {
      position: relative;
      width: $cell-size;
      height: $cell-size;
      overflow: hidden;
    }

    &-fail {
      border: red solid 1px;
      box-sizing: border-box;
    }

    &-delete {
      position: absolute;
      top: 0;
      left: 0;
      width: unit(16);
      height: unit(16);
      // background-color: var(--zui-color-gs-50);
      background-color: lightgray;
      font-size: unit(8);
      color: var(--zui-color-gs-10);
      cursor: pointer;
      @include flex-raw-center;

      border-radius: 100%;
    }

    &-mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: $cell-size;
      color: var(--zui-color-gs-10);
      background-color: rgba(50, 50, 51, 88%);
    }

    &-loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      box-sizing: border-box;
      padding-top: unit(8);
    }

    &-mask-message {
      display: inline-block;
      padding: unit(6) unit(4);
      font-size: var(--adm-font-size-4);
    }

    &-image {
      width: $cell-size;
      height: $cell-size;
    }
  }

  &-file-name {
    @include font-12;

    margin-top: unit(4);
    @include singleLineEllipsis;

    line-height: unit(16);
  }

  &-upload-button-wrap {
    position: relative;
    @include flex-raw-center;

    .upload-upload-button {
      background-color: var(--zui-color-gs-70);
      text-align: center;
      line-height: $cell-size;
      display: block;
      height: $cell-size;

      &-icon {
        color: #999;
        @include font-24;
      }
    }

    .upload-input {
      cursor: pointer;
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .upload-gap-measure {
    position: absolute;
    left: 0;
    top: 0;
    height: var(--gap-horizontal);
    width: 0;
  }
}

.defaultDeleteIcon {
  width: 8px;
  height: 8px;
  @include flex-raw-center;
}

.previewAndDownloadContainer {
  cursor: pointer;
  align-self: center;

  :global {
    .rc-dialog-content {
      border-radius: 0;
      min-height: unit(60);
    }

    .rc-dialog-body {
      min-height: unit(60);
    }
  }
}

.previewAndDownloadModal {
  width: unit(343);
  background-color: var(--color-white);
}

.previewAndDownloadItem {
  height: unit(60);
  @include flex-raw-center();
}

.disabledUpload {
  opacity: var(--input-opacity-disabled);
}
