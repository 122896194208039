// Here, provide a unit function for suiting for different device screen size
// Using vw/vh as a basic unit, your can get more information in https://developer.mozilla.org/zh-CN/docs/Web/CSS/length

/*
 usage
 
 div {
    hight: unit(30) // 30: real pixel size in design file.
 }
*/

/*
 proportion calculation, according to design size
 example: 32 -- 320 , 37.5 -- 375
*/

$vmFontSize: 37.5;
$vmDesign: 375;

@function unit($px) {
  @return calc($px / $vmFontSize) * 1rem;
}

// set basic font-size for rem, to make rem relate to vw
html {
  font-size: calc($vmFontSize / $vmDesign) * 100vw !important;
}

// set min size of device
@media screen and (max-width: 320px) {
  html {
    font-size: 32px !important;
  }
}

// set max size of device
@media screen and (min-width: 414px) {
  html {
    font-size: 37.5px !important;
  }
}
