@import "../../../index.module.scss";

.textReminder {
  @include flex-raw(start);

  align-items: flex-start;
}

.label {
  padding-left: unit(4);
  @include font-12;
}

.icon {
  flex-shrink: 0;
}
