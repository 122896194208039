@import "../../index.module.scss";

.cabinet {
  max-height: 0;
  overflow-y: hidden;
  // transition: max-height ease-in-out 0.3s;
}

.cabinetActive {
  max-height: unit(10000);
  margin-top: unit(16);
}
