@import "../../../../../../../index.module.scss";

.orderInfoSiteSurvey {
  background-color: var(--color-white);
  padding: unit(16);
  width: 100%;
  overflow: hidden;

  :global {
    textarea {
      @include font-14;
    }
  }
}

.cabinetCls {
  // margin-bottom: unit(4);
  margin-top: 0;
}

.accessories {
  padding-top: unit(16);
}

.checkboxCabinetCls {
  margin: unit(4) 0 !important;
  padding-left: unit(16);
  max-width: unit(279);
}

.partySubItemView {
  @include columnGap(10);

  & > div {
    padding-top: 0 !important;
    padding-bottom: 0 !important ;
  }
}

.dataPick {
  padding-top: unit(8);
}

.accessoryCls {
  margin-top: unit(4);
  background-color: var(--background-color-light-01);
  padding: 0 unit(16);
  overflow-y: auto;
}

.active3rdParty {
  max-height: unit(370);
}

.accessoryContainer {
  padding-top: unit(8);
}

.reminderContainer {
  padding-top: unit(16);
}

.uploadContainer {
  padding-top: unit(16);
}

.airlockSpecialCls {
  :global {
    label {
      display: none;
    }

    span {
      top: 23%;
    }
  }
}
