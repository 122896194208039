@import "../../../../../index.module.scss";

.subOrders {
  background-color: var(--color-white);
  padding: unit(16);
}

.labelLinkedOrders {
  @include font-14;

  font-weight: 500;
}

.linkedOrders {
  padding-top: unit(16);
}

.linkView {
  padding: 0 unit(16);
  height: unit(48);
  background-color: var(--background-color-secondary);
  @include flex-raw(space-between);

  color: var(--text-color-headline);
}

.cursePointer {
  cursor: pointer;
}

.linkView:not(:first-child) {
  margin-top: unit(8);
}

.icon {
  flex-shrink: 0;
}

.foldCabinet {
  padding-top: unit(16);
}

.moreDetailContainer {
  max-height: unit(216);
  overflow-y: auto;
}

.linkPcView {
  height: unit(56);
  padding: 0 unit(24);
  @include flex-raw(space-between);

  border: 1px solid var(--border-color-separator);
  border-radius: unit(3);
  margin-bottom: unit(16);
}

.linkPcView:last-child {
  margin-bottom: 0;
}

.linked {
  cursor: pointer;
  color: var(--color-interaction-link);
}

.foldCabinetVariantPc {
  justify-content: flex-start;
  width: fit-content;
}

.labelClassName {
  padding-right: unit(8);
}
