@import "../../../../../../../index.module.scss";

.orderInfoDnView {
  @include pcPageViewContainer;
  @include columnGap(16);
}

.dnView,
.moreDetailContainer {
  @include pcContentViewContainer;
}

.dnItem {
  @include pcContentViewItem;
}

.tableItem {
  display: block;
  width: 100%;
}

.dnItemFull {
  @include pcContentViewItemFull;
}
