@import "../../index.module.scss";

$item-height: 34px;

.picker {
  @include font-14;

  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.pickerHeader {
  flex-shrink: 0;
  border-bottom: solid 1px var(--border-color-inactive);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;

  .pickerHeaderButton {
    display: inline-block;
    padding: 8px;

    .pickerHeaderButtonDisabled {
      &,
      &:active {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }

  .pickerHeaderTitle {
    padding: 4px;
    text-align: center;
    color: var(--text-color-headline);
    flex: 1;
  }
}

.pickerBody {
  flex: 1;
  width: 100%;
  height: 100%;

  .pickerBodyPickerView {
    --height: 100%;
  }
}
