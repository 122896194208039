@import "./../../../../index.module.scss";

.moreDetail {
  padding-bottom: unit(16);
  position: relative;
  @include flex-raw(space-between);

  margin-bottom: unit(16);
}

.moreDetail::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: var(--border-color-active);
}

.name {
  padding-right: unit(18);
}

.text,
.quantify {
  @include font-14;

  font-weight: 500;
  line-height: unit(24);
  color: var(--text-color-headline);
}

.sn {
  @include font-12;

  font-weight: 300;
  line-height: unit(24);
  color: var(--text-color-body);
}
