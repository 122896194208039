@import "../../../index.module.scss";
$containerMinWidth: unit(276);
$inputHeight: unit(24);

.datePickerRoot {
  // margin-top: unit(-15);
  user-select: none;

  :global {
    .rc-input-group {
      @include flex-raw(flex-start);

      align-items: baseline;
    }

    .rc-input {
      padding-left: unit(8);
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
  }
}

.datePickerInput {
  :global {
    .rc-input {
      padding-top: unit(10);
    }
  }
}

.input {
  cursor: pointer;
}

.datePickerClassName {
  :global {
    .react-datepicker {
      @include font-14;

      user-select: none;
      border: none;
      box-shadow: var(--box-shadow-highlight);

      & > * {
        padding: var(--spacing-m);
      }
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__day-names {
      margin-bottom: -8px;

      .react-datepicker__day-name {
        width: 32px;
        margin: 0;
        color: var(--text-color-headline);
        font: var(--text-body-xs);
        user-select: none;
      }
    }

    .react-datepicker__month {
      margin: 0;
    }

    .react-datepicker__day {
      width: unit(32);
      height: unit(32);
      margin: 0;
      color: var(--text-color-headline);

      /* To align to the center */
      line-height: 32px;
      border-radius: 0;

      &:hover {
        background: var(--background-color-light-02);
      }

      &.react-datepicker__day--outside-month {
        color: var(--border-color-inactive);
      }

      &.react-datepicker__day--selected {
        color: var(--text-color-button);
        background: var(--color-interaction-link);
        border-radius: 0;

        &:hover {
          background: var(--fill-color-active);
        }
      }
    }

    .react-datepicker__header {
      padding-top: 0;
      background-color: var(--background-color-primary);
      border-bottom: 0;
    }

    .react-datepicker__day--disabled {
      color: var(--border-color-inactive);
    }
  }
}

.icon {
  display: inline-block;
  width: unit(16);
  line-height: unit(16);
  position: relative;
  top: unit(2);
}

.disabled {
  opacity: var(--input-opacity-disabled);
}
