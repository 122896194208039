@import "../../../../index.module.scss";

.indicatorNew {
  @include font-12;

  line-height: unit(12);
  display: inline-block;
  color: var(--color-information-90);
  // margin-left: unit(2);
  padding: unit(4) unit(6);
  background-color: var(--color-information-10);
  transform: scale(0.75);
  position: relative;
  top: unit(-2);
}
