.mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: block;
  width: 100%;
  height: 100%;

  &AriaButton {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &Content {
    z-index: 1;
  }
}
