@import "../../../../index.module.scss";

/** Mobile  */
.modifyContact {
  padding-bottom: 0;
  @include flex-column(space-between);
}

.title {
  @include font-16;

  font-weight: 500;
  line-height: unit(20);
  padding-bottom: unit(12);
  @include flex-raw(flex-start);
}

.views {
  padding: unit(16);
  width: 100%;
}

.buttons {
  @include flex-raw(space-between);

  width: 100%;
  padding: unit(24) unit(16);
}

.btn {
  width: calc((100% - unit(8)) / 2);
}

.new {
  @include font-14;

  font-weight: 600;
  color: var(--color-information-90);
  margin-left: unit(8);
  padding: unit(4) unit(12);
  background-color: var(--color-information-10);
}

/** Pc */
.modifyContactPc {
  @include columnGap(16);

  .title {
    @include font-24;

    font-weight: 600;
    padding-bottom: 0;
    line-height: unit(34);
  }

  .views {
    padding: 0;
    @include columnGap(16);
  }
}

.pcButtons {
  padding-top: unit(16);
  @include flex-raw(flex-end);

  gap: unit(16);
}
