@import "../../index.module.scss";

.toastMask {
  .toastWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .toastMain {
    display: inline-block;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    max-width: unit(204);
    max-height: 70%;
    padding: unit(24);
    overflow: auto;
    color: white;
    word-break: normal;
    background-color: rgba(0, 0, 0, 50%);
    border-radius: unit(3);
    pointer-events: all;
    @include font-18;

    line-height: 1.5;
    box-sizing: border-box;
  }

  .toastMainText {
    min-width: 0;
  }

  .toastMainIcon {
    padding: 35px 12px;
    min-width: 150px;

    .toastIcon {
      text-align: center;
      margin-bottom: 8px;
      line-height: 1;
    }
  }
}

.toastLoading {
  --size: 48px;

  margin: 0 auto 8px;
}
