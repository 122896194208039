@import "../../index.module.scss";

$container_padding_right: 436;
$right_part_width: 420;

.tabs {
  background-color: var(--background-color-light-01);
}

.tabs > div {
  align-self: start;
}

.tabs > div > div > nav {
  margin-left: unit(16);

  button {
    @include font-14;
  }
}

.tabs > div .tabItem > button {
  @include font-16;

  color: var(--text-color-headline);
  font-weight: 600;
}

.orderRequestMobile {
  :global {
    label {
      @include font-14;
    }
  }
}

.orderRequestPc {
  position: relative;

  .pcBasicInfoAndComments {
    position: absolute;
    right: unit(16);
    top: 0;
    width: unit($right_part_width);
    @include columnGap(16);
  }

  .tabs {
    :global {
      .rc-tabs-nav-list {
        gap: unit(40);
      }
    }
  }

  :global {
    .rc-tabs-nav {
      height: unit(52);
      padding-right: unit($container_padding_right);
    }

    .rc-tabs-nav-wrap {
      padding: 0 unit(16);
      margin: 0 unit(16);
      background-color: var(--color-white);
    }

    .rc-tabs-tab {
      padding: 0;
      @include flex-raw-center();

      min-width: unit(36);
    }

    .rc-tabs-nav-list {
      gap: unit(16);
    }

    .rc-tabs-nav-list::after {
      background-color: transparent;
    }
  }
}
