@import "../../index.module.scss";

.checkboxWrapper {
  position: relative;
  height: unit(32);
  @include flex-raw(flex-start);

  input[type="checkbox"] {
    display: none;
  }
}

.checkboxWrapperDisabled {
  opacity: var(--input-opacity-disabled);

  div[class*="checkbox"] {
    opacity: var(--input-opacity-disabled);
  }
}

.box {
  position: relative;
  width: unit(16);
  height: unit(16);
  border: 1px solid var(--text-color-headline);
  border-radius: unit(3);
  margin-right: unit(4);
  cursor: pointer;
}

.boxActive {
  background-color: var((--color-interaction-link));
  border-color: var((--color-interaction-link));
}

.box::before {
  content: "";
  position: absolute;
  top: 6%;
  left: 30%;
  width: unit(5);
  height: unit(10);
  border: solid transparent;
  border-width: 0 unit(2.5) unit(2.5) 0;
  transform: rotate(45deg);
}

.boxActive::before {
  border-color: var(--color-white);
}

.boxDisabled {
  background-color: var((--color-interaction-grey-disabled));
  border-color: var((--color-interaction-grey-disabled));
}
