@import "@../../../../../index.module.scss";

.modal {
  position: relative;

  :global {
    .rc-dialog-content {
      border-radius: unit(3);

      .rc-dialog-close {
        opacity: 1;
      }
    }
  }
}

.confirmVariant {
  display: block;
  padding: unit(52) unit(56) unit(40) unit(56);
  min-width: unit(709);
  border-radius: 3px;
}

.closeIcon {
  display: inline-block;
  position: absolute;
  right: unit(4);
  top: unit(4);
}

.icon {
  width: unit(48);
  height: unit(48);
}

.title {
  @include font-24;
}

.titleContent {
  font-weight: 500;
}

.contentClassName {
  padding: 0;
}

.footer {
  width: 100%;
  padding-top: unit(16);
  @include flex-raw(flex-end);
}

.button:last-child {
  margin-left: unit(8);
}

.titleWithIcon {
  @include font-20;

  .titleContent {
    padding-top: unit(16);
  }
}
