@import "../../../../../../../index.module.scss";

.finalGoodsRecipView,
.finalGoodsRecipNewView {
  @include pcPageViewContainer;
  @include columnGap(16);
}

.title {
  @include pcTitleFontWeight;
}

.personInfoView {
  @include pcContentViewContainer;
}

.infoItem {
  @include pcContentViewItem;
}

.btn {
  width: fit-content;
  align-self: end;
}

.new {
  @include font-14;

  display: inline-block;
  font-weight: 600;
  color: var(--color-information-90);
  margin-left: unit(8);
  padding: unit(4) unit(12);
  background-color: var(--color-information-10);
}
