@import "../../../../index.module.scss";

/** Mobile  */
.siteSurveyRsm {
  background-color: var(--background-color-light-01);
}

.pageContainer {
  padding: unit(16);
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
}

.contactView,
.siteSurveyView {
  padding: unit(16);
  background-color: var(--color-white);
  width: 100%;
  overflow-x: hidden;
}

.buttons {
  padding: unit(16) unit(24) unit(32) unit(24);
  @include flex-raw(space-between);

  background-color: var(--color-white);
}

.btn {
  width: 100%;
  height: unit(32) !important;
  padding: 0 !important;
}

/** Pc */
.siteSurveyRsmPc {
  @include pcTabPageContainer;
}

.contactPcView,
.servicePersonView {
  @include pcPageViewContainer;
  @include pcContentViewContainer;
}

.pageContent {
  @include pcTabPageContent;
}

.pcButtons {
  @include pcTabButtonContainer;
}

.pcTextItem {
  @include pcContentViewItem;
}

.thirdPartyView {
  @include pcPageViewContainer;
}
