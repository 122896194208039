@import "../../../../index.module.scss";

.installationInfoRsm {
  background-color: var(--background-color-light-01);
}

.pageContainer {
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
  padding: unit(16);
}

.uploadFileView {
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
}

.orderInfoView,
.orderContactView,
.uploadFileView {
  padding: unit(16);
  background-color: var(--color-white);
  width: 100%;
  overflow-x: hidden;
}

.warrantyPeriodReminder {
  padding: unit(10);
  background-color: var(--background-color-secondary);
  width: 100%;
}

.warrantyPeriod {
  padding-bottom: unit(8) !important;
}

.confirmButtons {
  width: 100%;
  @include flex-raw(space-between);
}

.confirmBtn {
  width: calc((100% - unit(8)) / 2) !important;
  height: unit(32) !important;
}

.buttons {
  padding: unit(16) unit(24) unit(32) unit(24);
  @include flex-raw(space-between);

  background-color: var(--color-white);
}

.btn {
  width: 100%;
  height: unit(32) !important;
  padding: 0 !important;
}

.uploadItem {
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(12);
}

.uploadTile {
  color: var(--text-color-body);
}

.fac {
  padding-top: unit(12);
}

.reminder {
  text-align: center;
}

.datePicker {
  margin-bottom: unit(8);
}

.expectedDate {
  padding-bottom: unit(6) !important;
}

/** Pc */
.installationFsePc {
  @include pcTabPageContainer;
}

.pageContentPc {
  @include pcTabPageContent;
  @include columnGap(16);
}

.orderInfoPcView {
  @include pcContentViewContainer;
}

.orderInfoPcView,
.uploadFilePcView {
  @include pcPageViewContainer;
}

.uploadFilePcView {
  @include columnGap(16);
}

.viewItem {
  @include pcContentViewItem;
}

.buttonsPc {
  @include pcTabButtonContainer;
}

.confirmButtonsPc {
  @include pcConfirmModalButton;
}
