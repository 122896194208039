@import "../../index.module.scss";

.basicView {
  width: 100%;
}

.basicViewLabel {
  display: flex;
  justify-content: start;
}

.basicViewMobile {
  display: flex;
  justify-content: space-between;

  .basicViewLabel {
    max-width: 70%;
    padding-right: unit(12);
    @include flex-raw(flex-start);

    align-items: flex-start;
  }

  .basicViewContainer {
    display: flex;
    justify-content: end;
  }
}

.labelText {
  @include singleLineEllipsis;
}

.indicatorCls {
  padding-left: 2px;
  display: flex;
  align-items: flex-start;
}

.labelIndicator {
  position: relative;
}

.indicatorDot::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  border-radius: 50%;
  background-color: var(--color-functional-error-0);
}

.indicatorStar::after {
  position: absolute;
  content: "*";
  left: 0;
  top: 0;
  color: var(--color-functional-error-0);
  @include font-24;

  line-height: unit(24);
  font-family: Arial, sans-serif !important;
}
