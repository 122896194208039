$container_padding_right: 436;

@mixin flex-raw($justify-content) {
  display: flex;
  align-items: center;
  justify-content: $justify-content;
}

@mixin flex-column($justify-content) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: $justify-content;
}

@mixin flex-raw-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin singleLineEllipsis {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin multiLineEllipsis($lineNumber) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lineNumber;
  overflow: hidden;
}

@mixin flex-column-gap($gap) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

/** Pc Common View Style */
@mixin pcTabPageContainer {
  background-color: var(--background-color-light-01);
}

@mixin pcTabPageContent {
  display: flex;
  flex-direction: column;
  row-gap: unit(16);
  padding-top: unit(16);
  padding-bottom: unit(24);
  min-height: unit(336);
  height: calc(100vh - unit(242));
  overflow-y: auto;
  padding-right: unit($container_padding_right);
}

@mixin pcPageViewContainer {
  padding: unit(16);
  margin-left: unit(16);
  background-color: var(--color-white);
}

@mixin columnGap($gap) {
  display: flex;
  flex-direction: column;
  row-gap: unit($gap);
}

@mixin pcContentViewContainer {
  display: flex;
  flex-wrap: wrap;
  gap: unit(16) unit(80);
}

@mixin pcContentViewItem {
  min-width: unit(300);
  width: calc((100% - unit(80)) / 2);
}

@mixin pcContentViewItemFull {
  min-width: unit(300);
  width: 100%;
}

@mixin pcTitleFontWeight {
  font-weight: bold;
}

@mixin pcTabButtonContainer {
  height: unit(72);
  @include flex-raw(flex-end);

  gap: unit(16);
  padding: 0 unit(16);
  background-color: var(--color-white);
}

@mixin pcConfirmModalButton {
  padding-top: unit(16);
  @include flex-raw(flex-end);

  gap: unit(16);
}
