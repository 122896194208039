@import "./../../../../index.module.scss";

$containerMinWidth: unit(276);
$inputHeight: unit(24);

.datePickerHeaderRoot {
  @include flex-raw(center);

  align-items: stretch;
  margin-bottom: unit(24);
}

.spacer {
  flex: 1;
}

.stepperButton {
  @include flex-column(center);

  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;

  svg {
    margin: auto;
    color: var(--text-color-headline);
  }
}

.pickerYearSelectWrapper {
  position: relative;
  @include flex-raw-center;

  svg {
    margin: auto;
  }
}

.pickerDateTitle {
  min-width: unit(84);
  color: var(--text-color-headline);
  @include font-14;
}
