@import "../../../../../index.module.scss";

.orderInfo {
  background-color: var(--background-color-light-01);
}

.pageContainer {
  padding: unit(16);
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
}

.buttons {
  padding: unit(16) unit(24) unit(32) unit(24);
  @include flex-raw(space-between);

  background-color: var(--color-white);
}

.btn {
  width: 100%;
  height: unit(32) !important;
  padding: 0 !important;
}

.reminder {
  text-align: center;
}
