@import "../../index.module.scss";

$height: unit(64);

.headerBar {
  position: relative;
  width: 100%;
  height: $height;
  @include flex-raw(space-between);

  gap: unit(24);
}

.headerBar::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: var(--background-color-light-04);
}

.tileAndLogo {
  @include flex-raw(flex-start);

  cursor: pointer;
}

.logo {
  height: unit(64);
  width: unit(64);
  padding: unit(16);
  @include flex-raw-center;
}

.title {
  @include flex-raw-center;

  font-weight: 700;
  @include font-16;
}

.subItem {
  @include flex-raw(flex-end);

  gap: unit(24);
  padding-right: unit(24);
  height: 100%;
}
