@import "../../../../../../../index.module.scss";

.orderInfoDnView {
  padding: unit(16);
  background-color: var(--background-color-primary);
  width: 100%;
  overflow: hidden;
}

.title {
  @include font-16;

  font-weight: 500;
  line-height: unit(24);
  color: var(--text-color-headline);
  padding-bottom: unit(12);
}

.notice {
  padding-bottom: unit(12);
}

.multipleOrderNoView {
  display: flex;
  flex-direction: column;
  gap: unit(8);
  padding-bottom: unit(16);
}

.moreDetailContainer,
.subOrderNoView {
  padding: unit(12) unit(10);
  background-color: var(--background-color-light-01);
}

.subOrderNoView {
  max-height: unit(360);
  overflow-y: auto;
}

.subOrderNoMoreDetail {
  padding-top: unit(8);
}

.cabinetClassName {
  margin-top: 0;
}

.multipleTitle {
  padding-bottom: 0 !important;
}
