@import "../../index.module.scss";

.overlay {
  background-color: var(--color-white);
  border: 1px solid var(--border-color-active-invert);
  border-radius: unit(4);
  box-shadow: 0 unit(4) unit(16) 0 var(--container-box-shadow);
  padding: unit(16) unit(12);
  opacity: 1;

  :global {
    .rc-tooltip-inner {
      background-color: var(--color-white);
      box-shadow: none;
      padding: 0;
      @include font-14();

      color: var(--text-color-headline);
      min-height: unset;
    }

    .rc-tooltip-arrow {
      border-color: var(--color-white);
    }

    .rc-tooltip-arrow::before {
      content: "";
      position: absolute;
      top: unit(-8);
      left: unit(-10);
      width: 0;
      height: 0;
      border-left: unit(10) solid transparent;
      border-right: unit(10) solid transparent;
      border-bottom: unit(8) solid var(--color-white);
    }
  }
}

.arrow {
  width: 0;
  height: 0;
  border-left: unit(16) solid transparent;
  border-right: unit(16) solid transparent;
  border-bottom: unit(16) solid var(--border-color-active-invert);
}

.icon {
  display: inline-block;
  box-sizing: content-box;
  width: unit(16);
  height: unit(16);
  padding: unit(10) 0;
}
