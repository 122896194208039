@import "../../../../index.module.scss";

.title {
  @include font-16;

  color: var(--text-color-headline);
  padding-bottom: unit(16);
}

.process {
  max-height: calc(100vh - unit(278));
  width: unit(320);
  overflow-y: auto;
}

.processPc {
  width: auto;
  max-height: calc(100vh - unit(408));
  min-height: unit(428);
}

.pcModal {
  align-self: center;

  :global {
    .rc-dialog-content {
      border-radius: unit(3);

      .rc-dialog-close {
        opacity: 1;
      }
    }
  }
}

.pcProcessContainer {
  display: block;
  padding: unit(52) unit(26) unit(40) unit(26);
  min-width: unit(478);
  border-radius: 3px;
  align-self: center;
}
