@import "../../../../../../../index.module.scss";

.orderProductInfoView {
  @include pcPageViewContainer;
  @include columnGap(16);
}

.saleBasicView {
  @include pcContentViewContainer;

  gap: unit(18) unit(80);
}

.infoInput {
  @include pcContentViewItem;
}

.saleMoreDetail {
  margin-top: 0;
}

.moreDetailContainer {
  max-height: unit(500);
  overflow-y: auto;
}
