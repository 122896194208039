@import "../../index.module.scss";

.containerWord {
  padding: unit(16) !important;
  min-height: unit(24);
}

.containerWord img {
  transform: scale(0.55) !important;
  transform-origin: left;
}

.modelContainer {
  margin: 10vh 0;

  :global {
    .rc-dialog-content {
      border-radius: 0;
      overflow: auto;
    }
  }
}
