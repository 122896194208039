@import "../../../../../../../index.module.scss";

.soldToPartyView {
  @include pcPageViewContainer;
  @include columnGap(16);
}

.title {
  @include pcTitleFontWeight;
}

.personInfoView {
  @include pcContentViewContainer;
}

.infoItem {
  @include pcContentViewItem;
}

.btn {
  width: fit-content;
  align-self: end;
}
