@import "../../../../index.module.scss";

.installationInfoSales,
.installUploadFileView {
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
}

.installationInfoSales {
  background-color: var(--background-color-light-01);
}

.pageContainer {
  padding: unit(16);
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
}

.installDateView {
  padding: unit(16);
  background-color: var(--color-white);
  width: 100%;
  overflow-x: hidden;
}

.facAndTrainRequireView,
.installUploadFileView,
.buttonsView {
  padding: unit(16);
  background-color: var(--color-white);
  width: 100%;
  overflow-x: hidden;
}

.datePickerCls {
  margin-bottom: unit(8);
}

.facAndTrainRequireView {
  gap: unit(16);
  display: grid;
}

.toggleView {
  display: grid;
  gap: unit(8);
}

.toggleView > section:first-child {
  height: unit(24);
}

.warrantyReminder {
  margin-top: unit(8);
  padding: unit(10);
  background-color: var(--background-color-secondary);
}

.buttons {
  padding: unit(16) unit(24) unit(32) unit(24);
  @include flex-raw(space-between);

  background-color: var(--color-white);
}

.btn {
  width: 100%;
  height: unit(32) !important;
  padding: 0 !important;
}

.uploadItem {
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(12);
}

.uploadTile {
  color: var(--text-color-body);
}

.confirmButtons {
  width: 100%;
  padding-top: unit(16);
  @include flex-raw(space-between);
}

.confirmBtn {
  width: calc((100% - unit(8)) / 2) !important;
  height: unit(32) !important;
}

.otherComments {
  padding-top: unit(4);

  :global {
    textarea::placeholder {
      @include font-14();
    }
  }
}

.facUploader {
  padding-bottom: 0 !important;
}

/** Pc */
.installationInfoSalesPc {
  @include pcTabPageContainer;
}

.pageContentPc {
  @include pcTabPageContent;
  @include columnGap(16);
}

.facAndTrainRequirePcView,
.installDatePcView,
.installUploadFilePcView {
  @include pcPageViewContainer;
}

.installDatePcView,
.installUploadFilePcView {
  @include columnGap(16);
}

.facAndTrainRequirePcView {
  @include columnGap(16);
}

.pcCabinetClass {
  margin-top: 0;
}

.pcDropDown {
  @include pcContentViewItem;
}

.togglePcView {
  @include flex-raw(flex-start);

  gap: unit(12);
}

.pcSwitchCabinet {
  justify-content: start;
}

.toggleLabelEn {
  span {
    display: inline-block;
    flex-shrink: 0;
    width: unit(196);
  }
}

.toggleLabelCn {
  span {
    display: inline-block;
    flex-shrink: 0;
    width: unit(136);
  }
}

.buttonsPc {
  @include pcTabButtonContainer;
}

.confirmButtonsPc {
  @include pcConfirmModalButton;
}
