@import "../../index.module.scss";

.notificationContentView {
  position: relative;
  @include font-14;
  @include flex-raw(start);

  padding: unit(24);
  width: unit(320);
  min-height: unit(96);
  border-left: unit(4) solid var(--border-color-inactive);
  border-radius: unit(6);
  background-color: var(--color-white);
}

.title {
  @include flex-raw(flex-start);

  align-items: flex-start;
  gap: unit(16);
}

.icon {
  width: unit(22);
  height: unit(22);
  flex-shrink: 0;
}

.titleText {
  max-width: 70vw;
  padding-right: unit(14);
  @include font-16;

  font-weight: bold;
  word-break: normal;
  max-height: unit(468);
  overflow-y: auto;
}

.closeIcon {
  position: absolute;
  top: unit(24);
  right: unit(24);
  width: unit(16);
  height: unit(16);
  cursor: pointer;
}

:global {
  .rc-notification-notice-content {
    padding: 0;
  }

  .rc-notification-notice {
    background-color: transparent;
  }

  .rc-notification {
    overflow-y: unset;
  }
}
