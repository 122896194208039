@import "../../../../index.module.scss";

.installationAll {
  background-color: var(--background-color-light-01);
}

.pageContainer {
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
  padding: unit(16);
}

.installationInfoView,
.trainingInfoView,
.filesView {
  padding: unit(16);
  background-color: var(--color-white);
  width: 100%;
  overflow-x: hidden;
}

.filesView {
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(12);
}

.warrantyPeriodReminder {
  padding: unit(10);
  background-color: var(--background-color-secondary);
  width: 100%;
}

.buttons {
  padding: unit(16) unit(24) unit(32) unit(24);
  @include flex-raw(space-between);

  background-color: var(--color-white);
}

.btn {
  width: 100%;
  height: unit(32) !important;
  padding: 0 !important;
}

.uploadItem {
  display: grid;
  gap: unit(12);
}

/** Pc */
.installationAllPc {
  @include pcTabPageContainer;
}

.pageContentPc {
  @include pcTabPageContent;
  @include columnGap(16);
}

.dateInfoPcView,
.installationInfoPcView,
.trainingInfoPcView,
.filesPcView {
  @include pcPageViewContainer;
}

.installationInfoPcView,
.trainingInfoPcView {
  @include pcContentViewContainer;
}

.filesPcView {
  @include columnGap(16);
}

.pcViewItem {
  @include pcContentViewItem;
}

.buttonsPc {
  @include pcTabButtonContainer;
}

.fullWidth {
  width: 100%;
}
