@import "../../../index.module.scss";

.fileUploadVariant {
  @include columnGap(8);
}

.confirmButtonsPc {
  @include pcTabButtonContainer;
}

.title {
  font-weight: bold;
  @include flex-raw(flex-start);

  align-items: flex-start;
}

.indicatorCls {
  padding-left: 2px;
  display: flex;
  align-items: flex-start;
}

.labelIndicator {
  position: relative;
}

.indicatorDot::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  border-radius: 50%;
  width: unit(6);
  height: unit(6);
  background-color: var(--color-functional-error-0);
}

.indicatorStar::after {
  position: absolute;
  content: "*";
  left: 0;
  top: 0;
  color: var(--color-functional-error-0);
  @include font-24;

  line-height: unit(24);
  font-family: Arial, sans-serif !important;
}
