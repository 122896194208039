@import "./../../../../index.module.scss";

.splitOrder {
  background-color: var(--background-color-light-01);
}

.pageContainer {
  padding: unit(16);
  @include flex-column(start);

  gap: unit(16);
  min-height: calc(100vh - unit(348));
}

.productView {
  padding: unit(16);
  width: 100%;
  background-color: var(--color-white);
}

.splitView {
  width: 100%;
  background-color: var(--color-white);
}

.moreDetailContainer {
  padding: 0 unit(16);
  max-height: unit(216);
  overflow-y: auto;
}

.splitDevices {
  padding: 0 unit(16) unit(16) unit(16);
  max-height: unit(216);
  overflow-y: auto;
}

.subProduct:last-child {
  margin-bottom: 0;
  padding-bottom: 0;

  &::after {
    display: none;
  }
}

.splitTitle {
  padding: unit(16) unit(16) 0 unit(16);
}

.labelTitle,
.mainProductTitle {
  @include font-14;

  line-height: unit(24);
  font-weight: 500;
  word-wrap: break-word;
  color: var(--text-color-headline);
}

.add {
  color: var(--color-interaction-link);
  cursor: pointer;
}

.splitItem {
  padding: unit(16) 0;
}

.itemIndex {
  @include font-12;

  color: var(--text-color-headline);
  padding-bottom: unit(8);
}

.itemContent {
  @include flex-raw(space-between);
}

.contentInfo {
  max-width: calc(100% - unit(80));
}

.infoDes {
  @include font-14;

  font-weight: 500;
  line-height: unit(24);
}

.infoMaterial {
  @include font-12;

  line-height: unit(16);
  color: var(--text-color-body);
}

.buttons {
  padding: unit(16) unit(24) unit(32) unit(24);
  @include flex-raw(space-between);

  width: 100%;
  background-color: var(--color-white);
}

.btn {
  width: 100%;
  height: unit(32) !important;
  padding: 0 !important;
}

.mainProduct {
  margin-bottom: 0;
  padding-bottom: unit(8);
}

.mainProduct::after {
  display: none;
}

.quantity {
  width: unit(36);
  height: unit(32);
  @include flex-raw-center;
}

.confirmButtons {
  width: 100%;
  @include flex-raw(space-between);
}

.confirmBtn {
  width: calc((100% - unit(8)) / 2) !important;
  height: unit(32) !important;
}

.splitReminder {
  padding: 0 unit(16);
  line-height: unit(18);

  :global {
    svg {
      position: relative;
      top: unit(3);
    }
  }
}

/** Pc */
.splitOrderPc {
  @include pcTabPageContainer;

  .moreDetailContainer {
    padding: 0;
  }

  .splitDevices {
    max-height: unit(378);
  }

  .splitTitle,
  .splitReminder,
  .splitDevices {
    padding: 0;
  }
}

.pageContentPc {
  @include pcTabPageContent;
  @include columnGap(16);

  padding-right: unit(452);
}

.productPcView,
.splitPcView {
  @include pcPageViewContainer;
  @include columnGap(16);
}

.viewListPc {
  @include pcContentViewContainer;
}

.pcViewItem {
  @include pcContentViewItem;
}

.buttonsPc {
  @include pcTabButtonContainer;
}

.confirmButtonsPc {
  @include pcConfirmModalButton;
}
