@import "../../../index.module.scss";

$menuMaxHeight: unit(44 * 5);
$menuItemHeight: unit(44);

.view {
  @include flex-raw(start);

  padding: unit(4) unit(8);
  gap: unit(8);
  cursor: pointer;
}

.disabledView {
  opacity: var(--input-opacity-disabled);
  cursor: not-allowed;
}

.viewText {
  @include font-14();

  color: var(--text-color-headline);
}

.arrow {
  @include flex-raw(center);

  transform-origin: center;
  transform: rotate(0);
  transition: all ease-in 0.1s;
  width: fit-content;
  cursor: pointer;
}

.arrowActive {
  transform: rotate(180deg);
}

.menu {
  max-height: $menuMaxHeight;
  background-color: var(--color-white);
  overflow-y: auto;
  box-shadow: 0 unit(4) unit(16) 0 var(--container-box-shadow);
  @include columnGap(12);

  padding: unit(4) 0;
}

.item {
  height: $menuItemHeight;
  padding: unit(16);
  color: var(--text-color-headline);
  @include font-14;

  line-height: unit(20);
  @include flex-raw(space-between);

  cursor: pointer;
  user-select: none;
}

.itemActive {
  color: var(--text-color-button);
  background-color: var(--color-interaction-link);
}

.selected {
  width: unit(7);
  height: unit(14);
  border: solid var(--color-white);
  border-width: 0 unit(2) unit(2) 0;
  transform: rotate(45deg);
}
