@import "../../../../index.module.scss";

.reminderGap {
  margin-top: unit(16);
}

.facIbaseCrmUpdate,
.facIbaseUpload,
.facIbaseAll {
  background-color: var(--background-color-light-01);
}

.pageContainer {
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
  padding: unit(16);
  min-height: calc(100vh - unit(328));
}

.installUploadFileView,
.buttonsView,
.filesView {
  background-color: var(--color-white);
  width: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
}

.btn {
  width: 100%;
  height: unit(32) !important;
  padding: 0 !important;
}

.uploadItem {
  padding: unit(16);
  grid-template-columns: auto;
  grid-gap: unit(16);
}

.installDateView,
.facDateView {
  padding: unit(16);
  background-color: var(--color-white);
}

.installDateView {
  padding-bottom: 0;
}

.warrantyPeriod {
  padding-bottom: unit(8) !important;
}

.warrantyReminder {
  background-color: #f8fafc;
  line-height: unit(20);
  font-size: unit(12);
  padding: unit(10);
  color: #606a76;
}

.info {
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
}

.buttons {
  padding: unit(16) unit(24) unit(32) unit(24);
  @include flex-raw(space-between);

  background-color: var(--color-white);
}

.confirmButtons {
  width: 100%;
  padding-top: unit(16);
  @include flex-raw(space-between);
}

.confirmBtn {
  width: calc((100% - unit(8)) / 2) !important;
  height: unit(32) !important;
}

.acceptDate {
  margin-bottom: unit(16);
}

.ibaseTitle {
  @include font-16;

  font-weight: 500;
  padding-bottom: unit(16);
  line-height: unit(24);
  word-break: break-word;
}

.moreDetailContainer {
  max-height: unit(422);
  overflow-y: auto;
}

.detailItem {
  padding: unit(12);
  background-color: var(--background-color-light-01);
  display: flex;
  flex-direction: column;
  gap: unit(16);
}

.cabinetClassName {
  margin-top: unit(8);
}

.facIbaseCrmUpdate {
  .installDateView:first-child {
    padding-top: unit(16);
  }
}

.warrantyPeriodReminder {
  line-height: unit(20);
  font-size: unit(14);
  color: #606a76;
  padding: unit(10);
  background-color: var(--background-color-secondary);
}

.installDateViewSpecial {
  @extend .installDateView;

  padding-top: unit(16);
}

/** Pc */
.facIbaseCrmUpdatePc {
  @include pcTabPageContainer;
}

.warrantyPeriodPc {
  padding-top: unit(16);
}

.pageContentPc {
  @include pcTabPageContent;
}

.facFilePcView,
.facDatePcView,
.infoPcView {
  @include pcPageViewContainer;
}

.infoPcView {
  @include columnGap(16);
}

.infoPcViewItem {
  @include pcContentViewContainer;
}

.viewItemPc {
  @include pcContentViewItem;
}

.buttonsPc {
  @include pcTabButtonContainer;
}

.confirmButtonsPc {
  @include pcConfirmModalButton;
}

.ibaseTitlePc {
  width: 100%;
  font-weight: 500;
}
