@import "../../index.module.scss";

.pagination {
  @include font-14;

  color: var(--text-color-body);
  user-select: none;

  :global {
    .rc-pagination-item {
      border: 0;
      @include font-14;

      height: unit(32);
      width: unit(32);
      line-height: unit(32);
    }

    .rc-pagination-item-active {
      font-weight: 600;
      border-bottom: 1px solid var(--border-color-active);
      color: var(--text-color-headline);
      border-radius: 0;
    }

    .rc-pagination-item-active a {
      color: var(--text-color-headline);
    }
  }
}

.select {
  width: unit(112) !important;
  height: unit(32) !important;
  margin-right: 0 !important;

  .selectIcon {
    display: block;
    @include flex-raw-center;

    transform: rotate(0);
    transform-origin: center;
    transition: transform ease-in 0.2s;
  }

  .selectActive {
    transform: rotate(180deg);
  }

  :global {
    .rc-select-selector {
      height: 100%;
      border: 1px solid var(--border-color-inactive) !important;
      border-radius: unit(3);
    }

    .rc-select-selection-search {
      display: inline-block;
      height: 100%;

      & > input {
        height: 100%;
        padding: 0;
      }
    }

    .rc-select-single:not(.rc-select-customize-input)
      .rc-select-selector
      .rc-select-selection-search-input {
      cursor: pointer;
    }

    .rc-select-arrow {
      display: block;
      height: 100%;
      @include flex-raw-center;
    }

    .rc-select-selection-item {
      display: inline-block;
      height: 100%;
      @include font-14;

      padding: 0 unit(4);
      @include flex-raw-center;
    }

    .rc-select-dropdown {
      border: 0;
      box-shadow: 0 unit(4) unit(16) 0 var(--container-box-shadow);
      min-height: unset;
    }

    .rc-select-item-option-content {
      @include font-14;
    }

    .rc-select-item-option {
      padding: unit(4) unit(8);
      color: var(--text-color-body);
    }

    .rc-select-item-option-active {
      background-color: var(--color-interaction-link);
      color: var(--color-white);
    }
  }
}

.disabled {
  opacity: var(--input-opacity-disabled);
}
