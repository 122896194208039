@import "../../../../index.module.scss";

.siteSurveyRse {
  background-color: var(--background-color-light-01);
}

.pageContainer {
  padding: unit(16);
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
}

.siteSurveyView {
  padding: unit(16);
  padding-bottom: 0;
  background-color: var(--color-white);
  width: 100%;
  overflow-x: hidden;
}

.buttons {
  padding: unit(16) unit(24) unit(32) unit(24);
  @include flex-raw(space-between);

  background-color: var(--color-white);
}

.btn {
  width: 100%;
  height: unit(32) !important;
  padding: 0 !important;
}

.datePicker {
  padding-bottom: unit(16);
}

.uploadCls {
  padding-top: 0;
}

.expectedDate {
  padding-top: unit(16);
  padding-bottom: unit(4) !important;
}

.cabinetCls {
  margin-top: unit(16);
}

.contactView,
.servicePersonView {
  padding: unit(16);
  background-color: var(--color-white);
  width: 100%;
  overflow-x: hidden;
}

/** Pc */
.siteSurveyRsePc {
  @include pcTabPageContainer;
}

.contactPcView,
.servicePersonView {
  @include pcPageViewContainer;
  @include pcContentViewContainer;
}

.siteSurveyViewPc {
  @include columnGap(16);
}

.pcPageContent {
  @include pcTabPageContent;
}

.pcButtons {
  @include pcTabButtonContainer;
}

.pcSwitchCabinet {
  justify-content: start !important;
}

.subView,
.pcUploadView {
  @include pcPageViewContainer;
  @include columnGap(16);
}

.subItemList {
  @include pcContentViewContainer;
}

.subItem {
  @include pcContentViewItem;
}
