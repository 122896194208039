@import "./../../../index.module.scss";

.orderListPc {
  margin: 0 unit(24);
  @include flex-column-gap(unit(16));

  .searchView {
    position: relative;
    height: unit(64);
    padding: 0 unit(16);
    @include flex-raw(flex-end);

    background-color: var(--color-white);
  }

  .logout {
    position: absolute;
    width: unit(120);
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
  }

  .logoutProd {
    cursor: auto;
  }

  .search {
    width: unit(642);
    margin-right: unit(8);

    :global {
      .rc-input-affix-wrapper {
        @include flex-raw(space-between);

        cursor: pointer;
      }

      .rc-input-suffix {
        display: block;
        height: 100%;
        @include flex-raw-center;
      }

      .rc-input {
        padding-right: unit(8);
      }
    }
  }

  .searchBtn {
    @include flex-raw(flex-start);
  }

  .searchText {
    @include font-12();

    line-height: unit(18);
    padding-left: unit(8);
    align-self: center;
  }

  .searchIcon {
    align-self: center;
    margin: 0 !important;
  }
}

.listView {
  padding: unit(24) unit(16);
  background-color: var(--color-white);
  @include flex-column-gap(unit(16));
}

.tabs {
  :global {
    .rc-tabs-nav-wrap {
      padding: 0;
    }

    .rc-tabs-nav {
      height: unit(36);
    }

    .rc-tabs-nav-list {
      gap: unit(40);
    }

    .rc-tabs-tab {
      padding: 0;
      min-width: unit(36);
    }

    .rc-tabs-nav-list::after {
      background-color: transparent;
    }
  }
}

.tabs > div {
  align-self: start;
}

.tabs > div > div > nav {
  margin-left: unit(16);

  button {
    @include font-14;
  }
}

.tabs > div .tabItem > button {
  @include font-16;

  color: var(--text-color-headline);
  font-weight: 600;
}

.table {
  width: 100%;
  height: calc(100vh - unit(346));
  // min-height: unit(564);
  overflow: auto;
}

.pagination {
  display: flex;
  justify-content: center;
  padding-top: unit(16);
}

.emptyView {
  @include flex-column(flex-start);

  align-items: center;
  @include font-16;

  color: var(--text-color-body);
  padding-top: unit(48);
}

.emptyText {
  position: relative;
  top: unit(-12);
}

.unSplitOrderSubOrder {
  @include flex-raw(flex-start);

  gap: unit(4);
}

.clearIcon {
  display: block;
  width: unit(15);
  height: unit(15);
  cursor: pointer;
}

.subActionView {
  @include flex-raw(center);

  gap: unit(16);
}

.subAction {
  cursor: pointer;
  color: var(--color-interaction-link);
}
