// in some case, your need to reset --safe-area-multiple
.safeArea {
  --multiple: var(--safe-area-multiple, 1);

  display: block;
  width: 100%;

  &-position-top {
    padding-top: calc(env(safe-area-inset-top) * var(--multiple));
  }

  &-position-bottom {
    padding-bottom: calc(env(safe-area-inset-bottom) * var(--multiple));
  }
}
