@import "../../index.module.scss";

:global {
  .rc-tabs-nav {
    height: unit(30);
    width: 100%;
  }

  .rc-tabs-nav-wrap {
    padding: 0 unit(16);
  }

  .rc-tabs-content-holder {
    width: 100%;
  }

  .rc-tabs-ink-bar {
    background: var(--text-color-headline);
  }

  .rc-tabs-top .rc-tabs-ink-bar,
  .rc-tabs-bottom .rc-tabs-ink-bar {
    height: 2px;
    z-index: 56;
  }

  .rc-tabs-tab-active {
    color: var(--text-color-headline) !important;
  }

  .rc-tabs {
    border: none;
    // gap: unit(16);
  }

  .rc-tabs-nav-list {
    width: 100%;
    @include flex-raw(flex-start);

    gap: unit(16);
  }

  .rc-tabs-nav-list::after {
    position: absolute;
    bottom: 0;
    z-index: 55;
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--border-color-inactive);
    content: "";
  }

  .rc-tabs-tab {
    font-size: unit(14);
    color: var(--text-color-body);
    font-weight: bolder;
    background: inherit;
    display: block;
    cursor: pointer !important;
    min-width: calc((100% - unit(48)) / 4);
  }

  .rc-tabs-tab:focus {
    outline: none;
  }

  .rc-tabs-tab-btn {
    line-height: unit(20);
    -webkit-tap-highlight-color: transparent;
    text-align: center;
  }

  .rc-tabs-nav-measure-ping-right::after,
  .rc-tabs-nav-wrap-ping-right::after {
    border: none;
  }

  .rc-tabs-nav-measure-ping-left::before,
  .rc-tabs-nav-wrap-ping-left::before {
    border: none;
  }

  .rc-tabs-content-holder,
  .rc-tabs-content-holder:focus,
  .rc-tabs-tab-btn,
  .rc-tabs-tab-btn:focus,
  .rc-tabs-content,
  .rc-tabs-content:focus,
  .rc-tabs-tabpane,
  .rc-tabs-tabpane-active {
    -webkit-tap-highlight-color: transparent;
    outline: none;
  }
}
