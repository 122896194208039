@import "../../../index.module.scss";

$containerHeight: unit(240);
$itemHeight: unit(34);

.pickerView {
  height: $containerHeight;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  background: var(--color-white);

  @include font-14;
}

.pickerView-column {
  height: 100%;
  flex: 1;
  user-select: none;
  touch-action: none;
  position: relative;
  z-index: 0;

  &-wheel {
    width: 100%;
    cursor: grab;
    position: absolute;
    top: calc(50% - $itemHeight / 2);
    left: 0;

    &::before {
      content: " ";
      display: block;
      position: absolute;
      width: 100%;
      height: 100vh;
      top: -100vh;
    }

    &::after {
      content: " ";
      display: block;
      position: absolute;
      width: 100%;
      height: 100vh;
      bottom: -100vh;
    }
  }

  &-item {
    padding: 0 unit(6);
    height: $itemHeight;
    display: flex;
    justify-content: center;
    align-items: center;

    &-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.pickerView-column-accessible {
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  top: calc($itemHeight * -1);
  z-index: 0;
  padding-bottom: 1px;

  > * {
    flex: 1;
    text-overflow: ellipsis;
  }

  &-current {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &-button {
    width: 100%;
    height: 100%;
  }
}

.pickerView-mask {
  position: absolute;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: none;

  &-top,
  &-bottom {
    flex: auto;
  }

  &-middle {
    height: $itemHeight;
    box-sizing: border-box;
    flex: none;
    border-top: solid 1px rgb(221, 227, 237);
    border-bottom: solid 1px rgb(221, 227, 237);
  }

  &-top {
    background: var(--color-white);
    mask: linear-gradient(
      0deg,
      rgba(0, 0, 0, 60%) 0%,
      rgba(0, 0, 0, 80%) 50%,
      rgba(0, 0, 0, 100%) 100%
    );
  }

  &-bottom {
    background: var(--color-white);
    mask: linear-gradient(
      180deg,
      rgba(0, 0, 0, 60%) 0%,
      rgba(0, 0, 0, 80%) 50%,
      rgba(0, 0, 0, 100%) 100%
    );
  }
}

.pickerView-loading-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pickerView-item-height-measure {
  position: relative;
  left: 0;
  top: 0;
  height: $itemHeight;
  width: 0;
  pointer-events: none;
}
