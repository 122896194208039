// Reference of ODS Css Variable

/* colors */
:root {
  // --color-black: rgb(0, 0, 0);
  // --color-white: rgb(255, 255, 255);
  // --color-cyan: rgb(0, 114, 239);

  /* colors.interaction */
  // --color-interaction-link: rgb(0, 114, 239);
  // --color-interaction-hover: rgb(0, 82, 170
  // --color-interaction-focused: rgb(0, 52, 108);
  // --color-interaction-active: rgb(0, 82, 170);
  // --color-interaction-disabled: rgba(0, 114, 239, 40%);
  --color-interaction-disabled-variable: rgba(0, 114, 239, 20%);

  /* colors.interaction.grey */
  // --color-interaction-grey-link: rgb(220, 227, 233);
  // --color-interaction-grey-hover: rgb(194, 205, 214);
  // --color-interaction-grey-focused: rgb(164, 176, 188);
  // --color-interaction-grey-active: rgb(164, 176, 188);
  // --color-interaction-grey-disabled: rgba(209, 217, 225, 0.4);

  /* colors.interaction.background */
  // --color-interaction-background-focused: rgb(236, 240, 244);
  // --color-interaction-background-invert-focused: rgb(78, 86, 95);

  /* colors.alert */
  // --color-functional-marketing: rgb(217, 233, 6);
  // --color-functional-marketing-invert: rgb(217, 233, 6);

  /* colors.alert.error */
  // --color-functional-error-0: rgb(231, 30, 30);
  // --color-functional-error-10: rgb(253, 240 240);
  // --color-functional-error-70: rgb(237, 79, 79);
  // --color-functional-error-120: rgb(80, 8, 8);

  /* colors.alert.warning */
  // --color-functional-warning-0: rgb(205, 75, 19);
  // --color-functional-warning-10: rgb(253, 241, 236);
  // --color-functional-warning-70: rgb(236, 110, 56);
  // --color-functional-warning-120: rgb(62, 23, 6);

  /* colors.alert.info */
  // --color-functional-info-0: rgb(152, 111, 0);
  // --color-functional-info-10: rgb(255, 244, 213);
  // --color-functional-info-40: rgb(253, 202, 63);
  // --color-functional-info-120: rgb(48, 35, 0);

  /* colors.alert.success */
  // --color-functional-success-0: rgb(30, 133, 101);
  // --color-functional-success-10: rgb(229, 249, 243);
  // --color-functional-success-50: rgb(58, 210, 163);
  // --color-functional-success-120: rgb(9, 39, 30);

  /* colors.information */
  // --color-information-120: rgb(0, 33, 68);
  // --color-information-110: rgb(0, 52, 108);
  // --color-information-100: rgb(0, 82, 170);
  // --color-information-90: rgb(0, 114, 239);
  // --color-information-80: rgb(26, 136, 255);
  // --color-information-70: rgb(51, 150, 255);
  // --color-information-60: rgb(93, 179, 255);
  // --color-information-50: rgb(125, 199, 255);
  // --color-information-40: rgb(153, 214, 255);
  // --color-information-30: rgb(177, 224, 255);
  // --color-information-20: rgb(213, 238, 255);
  // --color-information-10: rgb(234, 247, 255);
  // --color-information-zeiss: rgb(12, 45, 179);
  // --color-information-highlight: rgb(222, 222, 76);

  /* colors.documentation */
  // --color-documentation-pink: rgb(255, 0, 205);
  // --color-documentation-purple: rgb(148, 0, 255);
  // --color-documentation-white: rgb(255, 255, 255);
  // --color-documentation-black: rgb(0, 0, 0);

  /* textColor */
  // --text-color-body: rgb(96, 106, 118);
  // --text-color-body-invert: rgb(220, 227, 233);
  // --text-color-headline: rgb(50, 55, 62);
  // --text-color-headline-invert: rgb(242, 245, 248);
  // --text-color-button: rgb(255, 255, 255);
  // --text-color-button-invert: rgb(0, 114, 239);

  /* backgroundColor.light */
  // --background-color-primary: rgb(255, 255, 255);
  // --background-color-secondary: rgb(248, 250, 252);
  // --background-color-light-01: rgb(248, 250, 252);
  // --background-color-light-02: rgb(242, 245, 248);
  // --background-color-light-03: rgb(236, 240, 244);
  // --background-color-light-04: rgb(230, 235, 240);

  /* backgroundColor.dark */
  // --background-color-primary-invert: rgb(0, 0, 0);
  // --background-color-dark-01: rgb(50, 55, 62);
  // --background-color-dark-02: rgb(41, 45, 50);
  // --background-color-dark-03: rgb(32, 35, 39);
  // --background-color-dark-04: rgb(23, 25, 28);

  /* fill */
  // --fill-color-body: rgb(96, 106, 118);
  // --fill-color-body-invert: rgb(220, 227, 233);
  // --fill-color-headline: rgb(50, 55, 62);
  // --fill-color-headline-invert: rgb(242, 245, 248);
  // --fill-color-active: rgb(50, 55, 62);
  // --fill-color-active-invert: rgb(242, 245, 248);
  // --fill-color-inactive: rgb(194, 205, 214);
  // --fill-color-inactive-invert: rgb(108, 119, 132);

  /* borderColor */
  // --border-color-active: rgb(50, 55, 62);
  // --border-color-active-invert: rgb(242, 245, 248);
  // --border-color-inactive: rgb(194, 205, 214);
  // --border-color-inactive-invert: rgb(108, 119, 132);
  // --border-color-separator: rgb(230, 235, 240);
  // --border-color-separator-invert: rgb(32, 35, 39);

  /* customized variable */
  --input-opacity-disabled: 0.7;
  --container-box-shadow: rgba(25, 25, 25, 16%);
}
