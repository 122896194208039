@import "../../../index.module.scss";

.container {
  @include font-14;
  @include flex-raw(flex-start);

  align-items: flex-start;
}

.labelContainer {
  padding-right: unit(8) !important;
}

.label {
  color: var(--text-color-body);
  padding-right: 0;
}

.content {
  color: var(--text-color-headline);
}
