@import "../../../../../../../index.module.scss";

.infoSiteSurveyView {
  @include pcPageViewContainer;
  @include columnGap(16);
}

.siteSurvey {
  @include flex-raw(flex-start);

  gap: unit(8);
}

.cabinetClassName,
.checkboxCabinetCls {
  margin-top: 0;
}

.datePick {
  @include columnGap(16);
}

.datePicker {
  @include pcContentViewItem;
}

.accessoryContainer {
  padding: 0 unit(16);
  @include pcContentViewContainer;
}

.partySubItemView {
  @include columnGap(16);
}

.partyItemView {
  @include pcContentViewItem;
}
