@import "../../index.module.scss";

.pdfContainer {
  margin-top: unit(24);
}

.fileThumb {
  width: unit(44);
  height: unit(54);
}

.container {
  height: 100%;
}

.bar {
  height: unit(3);
  margin-bottom: unit(2);
  background-color: var(--zui-color-gs-40);
}

.square {
  height: unit(24);
  background-color: var(--zui-color-gs-40);
}

.pdfModal {
  height: fit-content;
}
