@import "../../../../../../../index.module.scss";

.orderRequestPartnerView {
  @include pcPageViewContainer;
  @include columnGap(16);
}

.title {
  @include pcTitleFontWeight;
}

.partnerInfoView,
.moreDetailContainer {
  @include pcContentViewContainer;
}

.partnerItem {
  @include pcContentViewItem;
}
