@import "./../../../../index.module.scss";

.datePickerYearDropdownRoot {
  position: relative;
}

.yearDropdownOpener {
  position: relative;
  display: flex;
  padding-left: unit(4);
  @include font-14;

  cursor: pointer;
  appearance: none;
}

.hiddenScrollbarScrollArea {
  position: relative;
  max-height: unit(240);
  overflow-x: clip;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-shadow: var(--box-shadow-highlight);

  &::-webkit-scrollbar {
    display: none;
  }
}

.selectableYearInDropdown {
  padding: unit(8) unit(4);
  color: var(--text-color-headline);
  @include font-14;

  text-align: left;
  cursor: pointer;
  background-color: var(--color-white);

  &:hover {
    background-color: var(--background-color-light-02);
  }
}

.selectableYearInDropdownSelected {
  color: var(--color-white);
  background-color: var(--color-interaction-link);

  &:hover {
    background-color: var(--fill-color-active);
  }
}

.selectableYearDropdownIcon {
  @include flex-raw-center;

  padding-left: unit(8);
  color: var(--text-color-headline);
  pointer-events: none;
}

.yearPopupParent {
  position: relative;
  width: 100%;
}

.yearPopupChild {
  position: absolute;
  inset: 0;
}

.zeissScrollbarWrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: unit(2);
  height: unit(240);
  background: var(--border-color-inactive);
}

.zeissScrollbarScroller {
  position: absolute;
  right: 0;
  z-index: 20;
  width: 2px;
  background: var(--border-color-active);
}
