body {
  margin: 0;
  font-family: -apple-system, blinkmacsystemfont, "Helvetica Neue", helvetica,
    "segoe ui", arial, roboto, "PingFang SC", miui, "Hiragino Sans GB",
    "Microsoft Yahei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
