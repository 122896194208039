@import "../../index.module.scss";

.table {
  :global {
    tbody {
      overflow-y: auto;
    }

    tbody tr td {
      height: unit(56);
      box-sizing: border-box;
      // background-color: var(--background-color-light-01);
      padding-right: unit(16);
    }

    tbody tr:last-child td {
      border-bottom: 0 !important;
    }

    thead tr th {
      height: unit(56);
      @include font-14;

      font-weight: 600;
      white-space: nowrap;
      position: sticky;
      top: 0;
      background-color: var(--background-color-light-01);
      border: 0;
    }

    thead tr th::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: var(--border-color-active);
    }
  }
}

.curseAuto {
  :global {
    tbody tr td {
      cursor: auto;
    }
  }
}

.actions {
  @include flex-raw(flex-start);

  gap: unit(8);
}

.actionItem {
  @include font-12;

  color: var(--color-interaction-link);
  cursor: pointer;
}
