@import "../../../index.module.scss";

.selectForMobile {
  padding: 0 unit(16) unit(16) unit(16);
  @include font-14;

  color: var(--text-color-headline);
  background-color: var(--color-white);
  min-height: unit(128);
}

.confirm {
  @include flex-raw(space-between);

  padding-bottom: unit(8);

  div {
    cursor: pointer;
  }
}

.cancel {
  color: var(--zui-color-gs-80);
}

.ok {
  color: var(--zui-color-pb-100);
}

.titleLabel {
  position: relative;
  color: var(--zui-color-pb-100);
  line-height: 2.5;
  width: fit-content;
}

.titleLabel::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: var(--zui-color-pb-100);
}

.title {
  position: relative;
  margin-bottom: 8px;
}

.title::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: var(--zui-color-gs-35);
}

.optionsList {
  max-height: unit(240);
  overflow-y: auto;
  padding: 0 unit(8) unit(24) unit(4);
}

.optionItem {
  position: relative;
  padding: unit(16) 0;
  line-height: unit(24);
  cursor: pointer;
  @include flex-raw(space-between);
}

.optionItem::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  height: 1px;
  width: 100%;
  background-color: var(--background-color-light-04);
}

.itemView {
  max-width: calc(100% - 12px);
  flex: auto;
  width: 100%;
  // @include singleLineEllipsis;
}

.textInputContainer {
  position: relative;
  // max-height: unit(58);

  :global {
    label {
      @include font-14;

      font-size: unit(14) !important;
      height: unit(24) !important;
    }
  }
}

.textInputContainerDisabled > div {
  opacity: var(--input-opacity-disabled);
}

.arrow {
  position: absolute;
  right: 0;
  top: 55%;
  @include flex-raw(center);

  transform-origin: center;
  transform: rotate(0);
  transition: all ease-in 0.15s;
  margin-right: unit(4);
}

.arrowActive {
  transform: rotate(180deg);
}

.textSearchContainer {
  position: relative;
  padding: unit(16) 0 0 0;
  overflow: hidden;

  :global {
    label {
      @include font-14;

      font-size: unit(14) !important;
    }
  }

  input {
    padding: unit(12) unit(20);
    min-width: unset;
    width: 100%;
  }

  input::placeholder {
    @include font-14;

    color: var(--fill-color-inactive);
  }

  & > div {
    width: 100%;
    margin: 0;
    padding: 0;
    padding-bottom: 0;
  }

  .clear {
    position: absolute;
    right: 0;
    top: 50%;
  }

  .search {
    position: absolute;
    left: 0;
    top: 50%;
  }
}
