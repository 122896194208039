@import "../../../index.module.scss";

.modalContainer {
  align-self: center;

  :global {
    .rc-dialog-content {
      border-radius: 0;
      min-height: unit(60);
    }
  }
}

.thumbImg,
.img {
  object-fit: contain;
  border: 0;
}

.thumbImg {
  width: unit(44);
  height: unit(54);
}

.img {
  max-width: 100%;
  max-height: 90vh;
}
