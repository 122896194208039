@import "../../../../../../../index.module.scss";

.orderInfoView {
  padding: unit(16);
  background-color: var(--background-color-primary);
  width: 100%;
  overflow: hidden;
}

.moreDetailItem {
  position: relative;
}

:global {
  input {
    @include font-14;

    font-size: unit(14) !important;
  }
}

.moreDetailItem:not(:first-child) {
  // padding-top: unit(24);
}

.moreDetailContainer {
  max-height: 50vh;
  padding: 0 unit(10);
  background-color: var(--background-color-light-01);
  overflow-y: auto;
}

.saleMoreDetail {
  padding-top: unit(24);
}

.orderRequestPartner {
  padding: unit(16);
  background-color: var(--background-color-primary);
  width: 100%;
  overflow-x: hidden;

  .title {
    @include font-16;

    font-weight: 500;
    line-height: unit(24);
    color: var(--text-color-headline);
  }
}

.orderInfoItem:not(:last-child),
.title {
  padding-bottom: unit(12);
}

.incotermsText {
  padding-bottom: 0 !important;
}

.cabinetCls {
  margin-top: unit(4);
  padding: unit(12) 0;
  background-color: var(--background-color-light-01);
}
