@import "./../../../index.module.scss";

$containerMinWidth: unit(276);
$inputHeight: unit(24);

.container {
  position: relative;
  min-width: $containerMinWidth;
  width: 100%;
  @include font-14;

  :global {
    .rc-input {
      padding: unit(2) 0 unit(8) 0;
      border: 0;
      width: 100%;
      color: var(--text-color-headline);
      line-height: $inputHeight;
    }

    .rc-input :disabled {
      background-color: transparent;
      color: var(--fill-color-inactive);
    }

    .rc-input:focus,
    .rc-input:focus-visible {
      outline: none;
    }

    .rc-input::placeholder {
      @include font-14;

      color: var(--fill-color-inactive);
    }

    .rc-input-disabled {
      cursor: not-allowed !important;
      background-color: transparent;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    input[type="number"] {
      appearance: textfield;
    }

    .rc-input-group {
      gap: unit(8);
    }
  }
}

.container::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: var(--border-color-inactive);
}

.container:focus::after {
  background-color: var(--border-color-active);
}

.error::after,
.error:focus::after {
  background-color: var(--color-functional-error-0);
}

.reset:focus,
.reset:focus-visible {
  outline: none;
}

.label {
  @include font-14;

  color: var(--text-color-body);
}

.errorText {
  @include font-12;

  line-height: unit(16);
  color: var(--color-functional-error-0);
}

.disabled {
  opacity: var(--input-opacity-disabled);
}
