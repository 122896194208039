@import "../../../index.module.scss";

.inputVariant {
  padding-bottom: 0;
  @include singleLineEllipsis;
}

.labelView {
  @include flex-raw(flex-start);

  align-items: flex-start;
  font-family: -apple-system, blinkmacsystemfont, "Helvetica Neue", helvetica,
    "segoe ui", arial, roboto, "PingFang SC", miui, "Hiragino Sans GB",
    "Microsoft Yahei", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.indicatorCls {
  padding-left: 2px;
}

.labelIndicator {
  width: 1px;
  height: 1px;
  position: relative;
}

.indicatorDot::after {
  position: absolute;
  content: "";
  left: unit(2);
  top: unit(4);
  border-radius: 50%;
  background-color: var(--color-functional-error-0);
  @include font-24();
}

.indicatorStar::after {
  position: absolute;
  content: "*";
  left: unit(2);
  top: unit(2);
  color: var(--color-functional-error-0);
  @include font-24();

  line-height: unit(24);
  font-family: Arial, sans-serif !important;
}
