@import "../../../../index.module.scss";

.siteSurveyAll {
  background-color: var(--background-color-light-01);
}

.pageContainer {
  padding: unit(16);
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
  min-height: calc(100vh - unit(328));
}

.contactView,
.siteSurveyView,
.siteSurveyReport {
  padding: unit(16);
  background-color: var(--color-white);
  width: 100%;
  overflow-x: hidden;
}

.buttons {
  padding: unit(16) unit(24) unit(32) unit(24);
  @include flex-raw(space-between);

  background-color: var(--color-white);
}

.btn {
  width: 100%;
  height: unit(32) !important;
  padding: 0 !important;
}

.uploadCls {
  padding-top: 0;
}

.expectedDate {
  padding-top: unit(16);
  padding-bottom: unit(4) !important;
}

/** PC */
.siteSurveyAllPc {
  @include pcTabPageContainer;
}

.pageContentPc {
  @include pcTabPageContent;
  @include columnGap(16);
}

.contactPcView,
.siteSurveyPcView,
.siteSurveyReportPcView {
  @include pcPageViewContainer;
}

.contactPcView,
.siteSurveyPcView {
  @include pcContentViewContainer;
}

.pcViewItem {
  @include pcContentViewItem;
}

.buttonsPc {
  @include pcTabButtonContainer;
}

.thirdParty {
  @include pcContentViewItemFull;
}
