@import "../../../index.module.scss";

// Client view height = 593.33px in 1280 * 720
.main {
  min-height: unit(520);
  min-width: unit(946);
  @include flex-raw(flex-start);

  height: calc(100vh - unit(64));
  align-items: initial;
}

.content {
  max-width: calc(100% - unit(50));
  min-width: calc(100% - unit(240));
  // max-height: calc(100vh - unit(64));
  width: 100%;
  background-color: var(--background-color-light-01);
}

.breadCrumb {
  width: 100%;
  height: unit(52);
  padding-left: unit(24);
}

.headerItem {
  height: 100%;
}
