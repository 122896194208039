@import "../../../index.module.scss";

.foldCabinet {
  @include flex-raw(space-between);

  cursor: pointer;
}

.label {
  @include font-14;

  line-height: unit(22);
  color: var(--color-interaction-link);
}

.icon {
  @include flex-raw(center);

  transform-origin: center;
  transform: rotate(0);
  transition: all ease-in 0.15s;
  margin-right: unit(4);
}

.iconActive {
  transform: rotate(180deg);
}

/** Fold Cabinet Pc Component */

.foldCabinetVariantPc {
  justify-content: flex-start;
  width: fit-content;
}

.labelClassName {
  padding-right: unit(8);
}
