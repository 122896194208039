@import "../../../../../index.module.scss";

.counter {
  @include flex-raw(start);
}

.icon {
  width: unit(22);
  height: unit(32);
  cursor: pointer;
  @include flex-raw-center;

  &.disabled {
    opacity: var(--input-opacity-disabled);
  }
}

.input {
  width: unit(28);
  height: unit(32);
  border: 0;
  @include font-14;

  font-weight: 500;
  line-height: unit(24);
  text-align: center;
}

.input:focus,
.input:focus-visible {
  outline: none;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
