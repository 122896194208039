@import "../../../../../../../index.module.scss";

.contactPerson {
  padding: unit(16);
  background-color: var(--background-color-primary);
  width: 100%;
  overflow: hidden;
}

.name {
  @include flex-raw(start);

  padding-bottom: unit(16);
}

.reminder {
  padding-bottom: unit(16);
}

.viewTitle {
  padding-bottom: unit(16);
}

.nameLabel,
.viewTitle {
  display: block;
  @include font-16;

  font-weight: 500;
  line-height: unit(20);
  word-wrap: break-word;
  width: 100%;
}

.nameLabel {
  padding-left: unit(10);
}

.btn {
  margin-top: unit(4);
  width: 100%;
}

.new {
  @include font-14;

  display: inline-block;
  font-weight: 600;
  color: var(--color-information-90);
  margin-left: unit(8);
  padding: unit(4) unit(12);
  background-color: var(--color-information-10);
}

.notContentPending {
  padding: 0;
}

.icon {
  flex-shrink: 0;
}

.invalidReminder {
  padding-bottom: unit(16);
}
