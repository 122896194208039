@import "../../../index.module.scss";

.step,
.toggle {
  padding-top: unit(4);
  cursor: pointer;
}

.toggle {
  position: relative;
  @include flex-raw(center);

  padding-bottom: unit(16);

  .labelText {
    padding-right: unit(6);
    color: var(--color-interaction-link);
    @include font-12;
  }
}

.label {
  position: relative;
  @include flex-raw(start);

  cursor: pointer;
}

.labelText {
  padding-left: unit(6);
  @include font-14;

  font-weight: 500;
  line-height: unit(20);
  color: var(--text-color-headline);
}

.pending,
.complete,
.reject,
.inactive {
  height: unit(20);
  width: unit(20);
  border-radius: 50%;
  @include flex-raw(center);
}

.complete {
  background-color: var(--color-interaction-disabled-variable);
}

.reject {
  background-color: var(--color-functional-info-40);
}

.pending {
  background-color: var(--color-interaction-link);
}

.inactive {
  background-color: var(--fill-color-inactive);
}

.cabinet {
  position: relative;
  margin: 0;
  padding-bottom: unit(16);
}

.cabinetContent {
  padding-left: unit(24);
}

.cabinet::before {
  position: absolute;
  left: unit(9);
  top: unit(4);
  content: "";
  width: 2px;
  height: 100%;
}

.stepActive::before {
  background-color: rgba(0, 114, 239, 20%);
}

.stepInactive::before {
  background-color: var(--border-color-separator);
}

.nonLine::before {
  display: none;
}

.expandIcon {
  transform: rotate(0);
  transform-origin: center;
  transition: transform ease-in 0.2s;
}

.expandIcon.active {
  transform: rotate(180deg);
}
