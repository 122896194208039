@import "../../index.module.scss";

.loader {
  width: 100%;
  height: auto;
}

.loaderView {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  height: fit-content;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 30%);
  @include flex-raw-center;
}
