@import "../../index.module.scss";

:global {
  .rc-dialog-wrap {
    display: flex;
    justify-content: center;
  }

  .rc-dialog-content {
    min-height: 168px;
    height: fit-content;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 20%) 0 3px 8px;
  }

  .rc-dialog-body {
    padding: 0;
    min-height: 168px;
  }

  .rc-dialog-fade-enter,
  .rc-dialog-fade-appear {
    animation-duration: 0.1s !important;
  }

  // mobile platform
  @media only screen and (max-width: 414px) {
    .rc-dialog-content,
    .rc-dialog-body {
      max-width: calc(100vw - 32px);
    }
  }

  @media only screen and (min-width: 768px) {
    .rc-dialog-content {
      min-width: 448px;
    }
  }

  .rc-dialog {
    width: fit-content;
  }
}

.modal {
  @include font-12;

  color: var(--zui-color-gs-90);
  margin: 0;
}
