@import "./../../../index.module.scss";

.orderList {
  padding: unit(16);
  background-color: var(--background-color-light-01);
  min-height: 100vh;
}

.listView {
  padding: unit(16);
  background-color: var(--color-documentation-white);
  margin-top: unit(16);
}

.time {
  @include font-12;

  line-height: unit(16);
  color: var(--text-color-body);
}

.goodsReceipt {
  @include font-20;

  line-height: unit(28);
  color: var(--text-color-headline);
}

.step {
  @include flex-raw(flex-start);

  margin-bottom: unit(12);
  cursor: pointer;

  .label {
    padding-right: unit(8);
    @include font-14;

    line-height: unit(20);
    color: var(--color-interaction-link);
  }

  .status {
    padding: 0 unit(12);
    @include font-12;

    line-height: unit(24);
    color: var(--text-color-button);
    background-color: var(--color-interaction-link);
    border-radius: unit(3);
    text-transform: uppercase;
  }
}

.contact {
  padding: unit(16) 0;
  @include flex-raw(flex-start);
}

.user {
  padding-left: unit(8);
  @include font-16;

  line-height: unit(22);
  color: var(--text-color-headline);
}

.textItem:not(:last-child) {
  padding-bottom: unit(12);
}

.search {
  display: inline-flex;
  align-items: center;
  width: 100%;
  position: relative;
  gap: unit(12);
  @include font-12();
}

.iconContainer {
  width: unit(16);
  height: unit(16);
  position: absolute;
  left: unit(8);
  @include flex-raw-center;
}

.searchInput {
  width: 100%;
  border: 0;
  height: unit(32);
  line-height: unit(22);
  font-size: unit(12);
  border-radius: unit(15);
  padding: 0 unit(12) 0 unit(30);
}

.searchInput:focus {
  border: 0;
  outline: none;
}

.searchInput::placeholder {
  @include font-12();

  color: var(--text-color-body);
  opacity: 0.35;
}

.buttonGroup {
  font-size: unit(14);
  height: unit(36);
  margin-top: unit(16);
  border-bottom: 1px solid var(--border-color-inactive);
  @include flex-raw(space-between);

  button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    padding: 0 unit(8);
    position: relative;
    line-height: unit(24);
    color: var(--text-color-body);
    margin-right: unit(8);
    font-weight: 500;
  }

  button:last-child {
    margin-right: 0;
  }

  .active {
    color: var(--text-color-headline);
  }

  .active::after {
    position: absolute;
    top: unit(28);
    left: 0;
    z-index: 55;
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--text-color-headline);
    content: "";
  }
}

.emptyView {
  height: calc(100vh - unit(136));
  @include flex-column(flex-start);

  align-items: center;

  @include font-16;

  color: var(--text-color-body);
}

.emptyText {
  position: relative;
  top: unit(-12);
}
