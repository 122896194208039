@import "../../../index.module.scss";

/* Accordion */
.accordion {
  border-top: 2px solid var(--border-color-active);
  border-bottom: 2px solid var(--border-color-active);
}

/* Accordion Item */
.accordionItem {
  display: block;
  padding: var(--spacing-nano) 0;
  border-top: 1px solid var(--border-color-inactive);

  &:first-child {
    border-top: none;
  }
}

.accordionButton {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing-m) 0;
  color: var(--text-color-headline);
  @include font-14;

  line-height: unit(20);
  text-align: left;
  background-color: var(--background-color-primary);
  border: 0;
  outline: none;
  cursor: pointer;

  @media screen and (min-width: 80rem) {
    padding: var(--spacing-l) 0;
    @include font-16;
  }

  &:hover,
  &:focus {
    background-color: var(--background-color-light-01);
  }

  &:active {
    background-color: var(--background-color-light-03);
  }
}

.accordionButtonContent {
  flex: 1;
  overflow: hidden;
  color: var(--text-color-headline);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.accordionButtonIcon {
  margin-bottom: 0;
  margin-left: var(--spacing-s);
  transition: transform 0.3s ease-in-out;

  &.isOpen {
    transform: rotate(180deg);
  }

  &.disabled {
    opacity: 0.4;
  }
}

.accordionPanel {
  overflow: hidden;
  transition: all 0.15s ease-out;
}

.accordionPanelContent {
  margin-bottom: var(--spacing-responsive-m);
}
