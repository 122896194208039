@import "./adaptation.module.scss";

@mixin font-10 {
  font-size: unit(10);
  letter-spacing: 0.005em;
  transform: scale(0.8);
}

@mixin font-12 {
  font-size: unit(12);
  letter-spacing: 0.005em;
}

@mixin font-14 {
  font-size: unit(14);
  letter-spacing: 0.005em;
}

@mixin font-15 {
  font-size: unit(15);
  letter-spacing: 0.005em;
}

@mixin font-16 {
  font-size: unit(16);
  letter-spacing: 0.005em;
}

@mixin font-18 {
  font-size: unit(18);
  letter-spacing: 0.005em;
}

@mixin font-20 {
  font-size: unit(20);
  letter-spacing: 0.005em;
}

@mixin font-22 {
  font-size: unit(22);
  letter-spacing: 0.005em;
}

@mixin font-24 {
  font-size: unit(24);
  letter-spacing: 0.005em;
}

@mixin font-28 {
  font-size: unit(28);
  letter-spacing: 0.005em;
}

@mixin font-32 {
  font-size: unit(32);
  letter-spacing: 0.005em;
}

@mixin font-36 {
  font-size: unit(36);
  letter-spacing: 0.005em;
}

@mixin font-48 {
  font-size: unit(48);
  letter-spacing: 0.005em;
}
