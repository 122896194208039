@import "../../../index.module.scss";

.textArea {
  padding: unit(12) 0;
  width: 100%;

  textarea::placeholder,
  label {
    font-family: -apple-system, blinkmacsystemfont, "Helvetica Neue", helvetica,
      "segoe ui", arial, roboto, "PingFang SC", miui, "Hiragino Sans GB",
      "Microsoft Yahei", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include font-14;
  }
}

.confirmButtons {
  width: 100%;
  padding-top: unit(16);
  @include flex-raw(space-between);
}

.confirmBtn {
  width: calc((100% - unit(8)) / 2) !important;
  height: unit(32) !important;
}

.confirmButtonsPc {
  @include pcConfirmModalButton;
}
