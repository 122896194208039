@import "../../index.module.scss";

.errorPage {
  width: 100%;
  height: 100vh;
  padding-top: unit(200);
  @include flex-column(flex-start);

  @include font-16;

  color: var(--text-color-body);
}

.text {
  padding-top: unit(16);
}

.pagePc {
  height: 100%;
  align-self: center;
}
