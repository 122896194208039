@import "../../index.module.scss";

/** Upload */
.fileUpload {
  @include columnGap(8);
}

/** File Upload */
.uploadItemContainer {
  position: relative;
  cursor: pointer;
  height: fit-content;
  width: 100%;
}

.uploadItem {
  width: 100%;
  height: unit(121);
  @include flex-column(center);

  border-radius: unit(8);
  border: 2px dashed var(--color-interaction-link);
  user-select: none;
}

.uploadItem.disable {
  opacity: var(--input-opacity-disabled);
  cursor: not-allowed;
}

.reminder {
  padding: unit(8) unit(12);
  @include font-12;

  font-weight: 400;
  color: var(--text-color-body);
}

.uploadInput {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: unit(121);
  border-radius: unit(8);
  cursor: pointer;
}

/** File Item */
.fileItem {
  width: 100%;
  height: unit(75);
  padding: unit(12) unit(16);
  border: 1px solid var(--border-color-inactive);
  border-radius: unit(8);
  @include flex-raw(start);

  gap: unit(16);
}

.fileIcon {
  width: unit(36);
  height: unit(48);
}

.fileIconImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.fileItemFail {
  border-color: var(--color-functional-error-0);
}

.fileContent {
  width: 100%;
  min-width: calc(100% - unit(160));
}

.name {
  @include font-14();

  font-weight: 550;
  color: var(--text-color-headline);
  line-height: unit(20);
  padding: unit(4) 0;

  @include multiLineEllipsis(3);
}

.size {
  @include font-12();

  color: var(--text-color-body);
  line-height: unit(22);
}

.actions {
  @include flex-raw(start);

  gap: unit(16);
}

.actionItem {
  cursor: pointer;
}

.progress {
  padding-top: unit(6);
}

/** Preview */

.modal {
  :global {
    .rc-dialog-content {
      position: relative;
      padding: unit(36) unit(48);
      border-radius: unit(3);
    }
  }
}

.img {
  @include flex-raw(center);
}

.pdf {
  @include flex-column(center);
}

.word {
  :global {
    .rc-dialog-content {
      position: relative;
      top: unit(24);
      max-width: unit(848) !important;
    }

    table {
      width: unset !important;
    }
  }
}

.preViewContent,
.previewPdf {
  min-width: unit(386);
  min-height: unit(515);
  max-height: unit(768);
  max-width: unit(709);
  overflow: auto;
}

.previewPdf {
  overflow: unset;
}

.preViewContent.img {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.closeIcon {
  display: inline-block;
  position: absolute;
  right: unit(0);
}
