@import "../../index.module.scss";

.head {
  overflow: hidden;
  position: relative;
}

.headContent {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: var(--text-color-body);
  @include flex-raw(center);
}
