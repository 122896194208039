@import "./../../index.module.scss";

/* SideNavigationCategory */
.sideNavigationLink {
  position: relative;
  color: var(--text-color-headline);
  background-color: var(--color-white);

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: unit(16);
    height: 100%;
    background-color: inherit;
    content: "";
    pointer-events: none;
  }

  &:hover {
    color: var(--color-interaction-hover);
    background-color: var(--background-color-light-02);
  }

  a {
    display: flex;
    gap: var(--spacing-xs);
    align-items: center;
    padding: var(--spacing-s) 16px;
    @include font-14;

    line-height: unit(20);
    background-color: transparent;
    border-left: 2px solid transparent;
    transition: padding 0.15s linear;
    cursor: pointer;

    &:focus-visible {
      outline: none;
    }

    &:hover {
      color: var(--color-interaction-hover);
      border-left-color: transparent;
    }

    &:focus {
      color: var(--color-information-110);
      border-left-color: transparent;
    }

    &.active:hover {
      border-left-color: var(--color-interaction-hover);
    }

    &.active:focus {
      border-left-color: var(--color-interaction-focused);
    }
  }

  &.openNavigation {
    a {
      padding: var(--spacing-s) 24px;
    }
  }

  &.active {
    background-color: var(--background-color-light-03);

    a {
      border-color: var(--border-color-active);
    }
  }

  span {
    flex-basis: unit(170);
    flex-shrink: 0;
  }
}

.sideNavigationCategory {
  padding: var(--spacing-0);
  border: none;
  cursor: pointer;
  background-color: initial;

  &.openNavigationExpanded {
    background-color: var(--background-color-light-01);
  }

  &:hover {
    button {
      border-left-color: transparent;
      color: unset;
      background-color: unset;
    }
  }

  &.active {
    background-color: var(--background-color-light-03);

    &:hover {
      button {
        border-left-color: var(--color-interaction-hover);
      }
    }
  }

  &.noneOpenNavigation {
    &:hover {
      button {
        color: var(--color-interaction-hover);
        background-color: var(--background-color-light-02);
      }
    }
  }
}

.sideNavigationCategoryHeader {
  position: relative;
  padding: var(--spacing-s) unit(16);
  color: var(--text-color-headline);
  background-color: transparent;
  border-left: 2px solid transparent;
  cursor: pointer;
  overflow: hidden;
  transition: padding 0.15s linear;
  @include font-14;

  font-weight: normal;

  &.openNavigation {
    padding: var(--spacing-s) 24px;
  }

  &.active {
    border-color: var(--border-color-active);
  }

  &.disabled {
    pointer-events: none;
  }

  &:hover {
    color: var(--color-interaction-hover);
    background-color: var(--background-color-light-02);
    border-left-color: transparent;

    & > svg {
      color: var(--color-interaction-hover);
    }
  }

  &.active:hover {
    border-left-color: var(--color-interaction-hover);
  }

  &:focus {
    color: var(--color-interaction-focused);
    border-left-color: transparent;

    & > svg {
      color: var(--color-interaction-focused);
    }
  }

  &.active:focus {
    border-left-color: var(--color-interaction-focused);
  }

  & > div {
    display: inline-flex;
    gap: var(--spacing-xs);
    align-items: center;
    min-width: unit(12);
    color: inherit;
    white-space: normal;
  }

  span {
    flex-basis: unit(170);
    flex-shrink: 0;
  }
}

.sideNavigationCategoryContent {
  & > div {
    margin-bottom: var(--spacing-s);
  }
}

/* SideNavigationSubCategory */
.sideNavigationSubCategory {
  color: var(--text-color-body);
  @include font-14;

  background-color: var(--background-color-light-01);

  &:hover {
    background-color: var(--background-color-light-04);
  }

  &.active {
    background-color: var(--background-color-light-03);
  }
}

.path {
  display: inline-flex;
  width: 100%;
  padding: var(--spacing-xs) var(--spacing-m) var(--spacing-xs) unit(44);
  border-left: unit(2) solid transparent;
  line-height: unit(20);

  &.active {
    border-color: var(--border-color-active);
  }
}

/* SideNavigation */
.sideNavigation {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: 49px;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  border: none;
  border-right: 1px solid var(--border-color-separator);
  transition: max-width 0.15s linear;

  a {
    display: inline-flex;
    color: inherit;
    text-decoration: none;
  }

  svg {
    position: relative;
    flex-shrink: 0;
  }

  &.openNavigation {
    max-width: unit(240);
    overflow-y: auto;
  }
}

.navigationHeader {
  display: grid;
  align-items: center;
  width: 100%;
}

.sideNavigationToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  height: 40px;
  margin: var(--spacing-0);
  padding: var(--spacing-0) var(--spacing-s);
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: var(--color-interaction-hover);
  }

  svg {
    transform: rotate(180deg);
  }

  &.openNavigation {
    justify-self: flex-end;
    padding: var(--spacing-0) var(--spacing-m);

    svg {
      transform: rotate(0);
    }
  }
}

.sideNavigationDivider {
  width: 100%;
  height: 1px;
  margin: var(--spacing-xs) var(--spacing-0);
  background-color: var(--border-color-separator);
}
