@import "./../../index.module.scss";

.breadCrumb {
  @include flex-raw(flex-start);
}

.nodeClassName {
  @include flex-raw(flex-start);

  cursor: pointer;
  user-select: none;
}

.label {
  @include font-14;

  line-height: unit(20);
  color: var(--color-interaction-link);
}

.icon {
  width: unit(18);
  height: unit(18);
  @include flex-raw-center;

  margin: 0 unit(8);
}
