@import "../../../../index.module.scss";

.installationFse {
  background-color: var(--background-color-light-01);
}

.pageContainer {
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
  padding: unit(16);
  min-height: unit(443);
}

.installationInfoView,
.filesView {
  padding: unit(16);
  background-color: var(--color-white);
  width: 100%;
  overflow-x: hidden;
}

.filesView {
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
}

.warrantyPeriodReminder {
  margin-top: unit(8);
  padding: unit(10);
  background-color: var(--background-color-secondary);
}

.buttons {
  padding: unit(16) unit(24) unit(32) unit(24);
  @include flex-raw(space-between);

  background-color: var(--color-white);
}

.btn {
  width: 100%;
  height: unit(32) !important;
  padding: 0 !important;
}

.uploadItem {
  display: grid;
  gap: unit(12);
}

.confirmButtons {
  width: 100%;
  padding-top: unit(16);
  @include flex-raw(space-between);
}

.confirmBtn {
  width: calc((100% - unit(8)) / 2) !important;
  height: unit(32) !important;
}

.planedEndTime {
  padding-bottom: unit(4) !important;
}

.actualInstallEndDate {
  margin-bottom: unit(24);
}

.actualInstallStartDate {
  margin-bottom: unit(12);
}

/** Pc */
.installationInfoRsmPc {
  @include pcTabPageContainer;
}

.pageContentPc {
  @include pcTabPageContent;
  @include columnGap(16);
}

.installationInfoPcView {
  @include pcContentViewContainer;
}

.installationInfoPcView,
.filesPcView {
  @include pcPageViewContainer;
}

.filesPcView {
  @include columnGap(16);
}

.viewItem {
  @include pcContentViewItem;
}

.viewItemFull {
  @include pcContentViewItemFull;
}

.buttonsPc {
  @include pcTabButtonContainer;
}

.confirmButtonsPc {
  @include pcConfirmModalButton;
}
