@import "../../../../index.module.scss";

.orderHeader {
  background-color: var(--background-color-light-01);
  padding: unit(16);
}

.pcHeader {
  padding: 0;
  padding: unit(16);
  background-color: var(--color-white);
}

.mobileView {
  background-color: var(--color-white);
  padding: unit(16);
}

.step {
  @include flex-raw(start);
}

.stepLabel {
  padding-right: unit(4);
  color: var(--color-interaction-link);
  white-space: nowrap;
  cursor: pointer;
}
