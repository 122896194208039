@import "../../../index.module.scss";

.uploadVariant {
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(12);
}

.reminder {
  @include font-12;

  color: var(--text-color-body);
  opacity: 0.5;
}

.deleteReminder {
  @include font-14;

  text-align: center;
}

.confirmButtons {
  width: 100%;
  padding-top: unit(16);
  @include flex-raw(space-between);
}

.confirmBtn {
  width: calc((100% - unit(8)) / 2) !important;
  height: unit(32) !important;
}

.uploadErrorReminder {
  font-size: unit(14) !important;
}

.title {
  @include font-14;

  color: var(--text-color-body);
  @include flex-raw(flex-start);

  align-items: flex-start;
}

.indicatorCls {
  padding-left: 2px;
  display: flex;
  align-items: flex-start;
}

.labelIndicator {
  position: relative;
}

.indicatorDot::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  border-radius: 50%;
  width: unit(6);
  height: unit(6);
  background-color: var(--color-functional-error-0);
}

.indicatorStar::after {
  position: absolute;
  content: "*";
  left: 0;
  top: 0;
  color: var(--color-functional-error-0);
  @include font-24;

  line-height: unit(24);
  font-family: Arial, sans-serif !important;
}
