@import "../../../index.module.scss";

$border-color: var(--color-interaction-link);
$action-bg-color: #fff;

.btn {
  position: relative;
}

.actions {
  position: absolute;
  left: 50%;
  z-index: 10;
  min-width: 100%;
  width: fit-content;
  transform: translateX(-50%);
  padding: unit(4) unit(8);
  border-radius: unit(3) unit(3) 0 0;
  border: 1px solid $border-color;
  background-color: $action-bg-color;
}

.actionItem {
  @include font-14;

  font-weight: 500;
  color: var(--color-information-90);
  line-height: unit(24);
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  padding: unit(6) 0;
}

.actionItem:not(:last-child) {
  border-bottom: 1px solid $border-color;
}

.inner {
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translate(-50%) rotate(-135deg);
  bottom: unit(-4.5);
  width: unit(8);
  height: unit(8);
  z-index: 11;
  border: 1px solid $border-color;
  border-right: 0;
  border-bottom: 0;
  background-color: $action-bg-color;
}
