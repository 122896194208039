@import "../../index.module.scss";

.errorColor {
  color: var(--zui-color-gs-110);
}

.smallView {
  @include font-12();
  @extend .errorColor;
}

.mediumView {
  @include font-14();
  @extend .errorColor;
}
