@import "../../../../index.module.scss";

.facIbaseAll {
  background-color: var(--background-color-light-01);
}

.installUploadFileView,
.buttonsView,
.filesView {
  background-color: var(--color-white);
  width: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
}

.btn {
  width: 100%;
  height: unit(32) !important;
  padding: 0 !important;
}

.uploadItem {
  padding: unit(16);
  grid-template-columns: auto;
  grid-gap: unit(16);
}

.installDateView,
.facDateView {
  padding: unit(16);
  background-color: var(--color-white);
}

.pageContainer {
  display: grid;
  grid-template-columns: auto;
  grid-gap: unit(16);
  padding: unit(16);
  min-height: calc(100vh - unit(328));
}

.warrantyPeriodReminder {
  background-color: #f8fafc;
  line-height: unit(20);
  font-size: unit(14);
  padding: unit(10);
  color: #606a76;
}

.ibaseTitle {
  width: 100%;
  @include font-16;

  font-weight: 500;
  line-height: unit(24);
  word-break: break-word;
  padding-bottom: unit(16);
}

.moreDetailContainer {
  max-height: unit(422);
  overflow-y: auto;

  @include columnGap(12);
}

.buttons {
  padding: unit(16) unit(24) unit(32) unit(24);
  @include flex-raw(space-between);

  background-color: var(--color-white);
}

.confirmButtons {
  width: 100%;
  padding-top: unit(16);
  @include flex-raw(space-between);
}

.confirmBtn {
  width: calc((100% - unit(8)) / 2) !important;
  height: unit(32) !important;
}

.subDateView {
  padding-top: unit(12);
}

.detailItem {
  padding: unit(8) unit(12);
  background-color: var(--background-color-light-01);
}

.ownerFse {
  padding-top: unit(12);
}

/** Pc */
.facIbaseAllPc {
  @include pcTabPageContainer;
}

.ibaseTitlePc {
  width: 100%;
  @include font-16;

  font-weight: 500;
  line-height: unit(24);
  word-break: break-word;
}

.pageContentPc {
  @include pcTabPageContent;
}

.facFilePcView,
.facDatePcView,
.infoPcView {
  @include pcPageViewContainer;
}

.facDatePcView,
.infoPcView {
  @include columnGap(16);
}

.viewPc {
  @include pcContentViewContainer;
}

.infoPcViewItem {
  @include pcPageViewContainer;
}

.viewItemPc {
  @include pcContentViewItem;
}

.buttonsPc {
  @include pcTabButtonContainer;
}
