@import "../../index.module.scss";

.popup {
  --z-index: var(--adm-popup-z-index, 1000);

  position: fixed;
  z-index: 1000;
}

.popupBody {
  position: fixed;
  background-color: var(--color-white);
  z-index: calc(var(--z-index) + 10);

  .popupCloseIcon {
    position: absolute;
    z-index: 100;
    cursor: pointer;
  }

  &-position-bottom {
    width: 100%;
    bottom: 0;
    left: 0;

    .popupCloseIcon {
      right: 8px;
      top: 8px;
    }
  }

  &-position-top {
    width: 100%;
    top: 0;
    left: 0;

    .popupCloseIcon {
      right: 8px;
      bottom: 8px;
    }
  }

  &-position-left {
    height: 100%;
    top: 0;
    left: 0;

    .popupCloseIcon {
      right: 8px;
      top: 8px;
    }
  }

  &-position-right {
    height: 100%;
    top: 0;
    right: 0;

    .popupCloseIcon {
      left: 8px;
      top: 8px;
    }
  }
}

.popupCloseIcon {
  cursor: pointer;
  padding: 4px;
  line-height: 1;
  color: var(--adm-color-weak);
  @include font-16;
}
