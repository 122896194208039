@import "../../../index.module.scss";

.modal {
  @include flex-column(center);

  :global {
    .rc-dialog-content {
      border-radius: 0;
    }
  }
}

.content {
  padding: unit(24) unit(12);
}

.closeIcon {
  position: absolute;
  bottom: unit(-48);
  left: 50%;
  height: unit(24);
  width: unit(24);
  transform: translateX(-50%);
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 100%;
  @include flex-raw-center;
}
