@import "../../index.module.scss";

.imageViewer {
  &-content {
    width: 100vw;
    height: 100vh;
    touch-action: none;
    user-select: none;
  }

  &-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 1;
  }

  &-slides {
    height: 100%;
    position: relative;
    z-index: 1;
    cursor: grab;
    touch-action: none;

    &-inner {
      height: 100%;
      white-space: nowrap;

      > * {
        margin-right: 16px;
      }
    }
  }

  &-slide {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  &-control {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: grab;
    touch-action: none;
  }

  &-image-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 414px) {
      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }

    @media only screen and (min-width: 414px) {
      img {
        display: block;
        max-width: 80%;
        max-height: 80%;
      }
    }
  }

  &-indicator {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 12px;
    transform: translateX(-50%);
    color: var(--zui-color-gs-20);
    @include font-12;
  }
}
