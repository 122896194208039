@import "../../../../index.module.scss";

.commentsContainer {
  overflow-y: auto;
  padding: unit(16);
  background-color: var(--color-white);
  width: 100%;

  :global {
    textarea {
      @include font-14;
    }
  }
}

.commentsContainerPc {
  min-height: unit(164);
  height: calc(100vh - unit(426));
}

.textarea {
  padding: 0 unit(12);
  position: relative;
  top: unit(-10);
}
