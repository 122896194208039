@import "../../../index.module.scss";

.label {
  @include font-14;

  color: var(--text-color-body);
  line-height: unit(20);
}

.content {
  position: relative;
  @include font-14;

  display: block !important;
  font-weight: 500;
  color: var(--text-color-headline);
  @include flex-raw-center;

  text-align: end;
  // @include singleLineEllipsis;

  line-height: unit(20);
  max-width: 70%;
  word-break: break-word;
}

.container:not(:last-child) {
  padding-bottom: unit(16);
}
