@import "../../../../index.module.scss";

.subChildren {
  @include font-14;

  line-height: unit(24);
}

.labelInfo {
  @include flex-raw(start);
}

.status {
  padding: unit(2);
  margin-left: unit(8);
  width: fit-content;
  border-radius: unit(3);
  @include font-12;

  text-transform: uppercase;
}

.statusText {
  display: block;
  transform: scale(0.83);
}

.itemLabel {
  color: var(--text-color-body);
}

.itemValue {
  display: inline-block;
  color: var(--text-color-headline);
  word-wrap: break-word;
  max-width: unit(240);
}

.time {
  @include font-12;

  color: var(--text-color-body);
}
