@import "../../../index.module.scss";

.selectForMobile {
  padding: 0 unit(16) unit(16) unit(16);
  @include font-14;

  line-height: unit(24);
  color: var(--text-color-headline);
  background-color: var(--color-white);
}

.confirm {
  @include flex-raw(space-between);

  padding-bottom: unit(8);

  div {
    cursor: pointer;
  }
}

.btn {
  width: calc((100% - unit(8)) / 2) !important;
}

.titleLabel {
  position: relative;
  color: var(--zui-color-pb-100);
  line-height: 2.5;
  width: fit-content;
}

.titleLabel::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: var(--zui-color-pb-100);
}

.title {
  position: relative;
  margin-bottom: 8px;
}

.title::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: var(--zui-color-gs-35);
}

.optionsList {
  padding: 0 unit(8) unit(24) unit(4);
  height: unit(220);
  overflow-y: auto;
}

.optionItem {
  position: relative;
  padding: unit(16) 0;
  cursor: pointer;
  @include flex-raw(space-between);
}

.itemView {
  max-width: calc(100% - 12px);
  flex: auto;
  width: 100%;
  @include singleLineEllipsis;
}

.optionItem::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  height: 1px;
  width: 100%;
  background-color: var(--background-color-light-04);
}

.textInputContainer {
  position: relative;
  padding: unit(16) unit(16) 0 unit(16);
  overflow: hidden;

  :global {
    label {
      @include font-14;

      font-size: unit(14) !important;
    }
  }

  input {
    padding: unit(12) unit(20);
    min-width: unset;
    width: 100%;
  }

  input::placeholder {
    @include font-14;

    color: var(--fill-color-inactive);
  }

  & > div {
    width: 100%;
    margin: 0;
    padding: 0;
    padding-bottom: 0;
  }
}

.clear {
  position: absolute;
  right: unit(16);
  top: 55%;
}

.search {
  position: absolute;
  left: unit(16);
  top: 55%;
}

/** Pc  */
.pcSearchContent {
  :global {
    .rc-input-group {
      @include flex-raw(flex-start);

      height: unit(34);
    }

    .rc-input {
      padding: 0 unit(8);
    }

    .rc-input-group-addon {
      display: inline-block;
      width: unit(16);
      height: 100%;
      position: relative;
      cursor: pointer;

      svg {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }
}

.confirmPc {
  @include flex-raw(flex-end);

  gap: unit(16);
  padding: unit(24) unit(16) 0;
}
