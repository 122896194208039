.space-item {
  flex: none;
}

.space {
  display: inline-flex;

  --gap: 8px;
  --gap-vertical: var(--gap);
  --gap-horizontal: var(--gap);

  &-vertical {
    flex-direction: column;

    > .space-item {
      margin-bottom: var(--gap-vertical);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-horizontal {
    flex-direction: row;

    > .space-item {
      margin-right: var(--gap-horizontal);

      &:last-child {
        margin-right: 0;
      }
    }

    &.space-wrap {
      flex-wrap: wrap;
      margin-bottom: calc(var(--gap-vertical) * -1);

      > .space-item {
        padding-bottom: var(--gap-vertical);
      }
    }
  }

  &.space-block {
    display: flex;
  }

  &-align {
    &-center {
      align-items: center;
    }

    &-start {
      align-items: flex-start;
    }

    &-end {
      align-items: flex-end;
    }

    &-baseline {
      align-items: baseline;
    }
  }

  &-justify {
    &-center {
      justify-content: center;
    }

    &-start {
      justify-content: flex-start;
    }

    &-end {
      justify-content: flex-end;
    }

    &-between {
      justify-content: space-between;
    }

    &-around {
      justify-content: space-around;
    }

    &-evenly {
      justify-content: space-evenly;
    }

    &-stretch {
      justify-content: stretch;
    }
  }
}
