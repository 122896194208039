@import "../../index.module.scss";

.phoneCall {
  @include font-14;

  color: var(--color-information-90);
  cursor: pointer;
  text-decoration: none;
  @include flex-raw(start);
}

.invalidNumber {
  color: var(--zui-color-gs-60);
}

.callIcon {
  display: inline-block;
  @include flex-raw-center;

  width: unit(20);
  height: unit(20);
  margin-left: unit(12);
  background-color: var(--color-interaction-link);
  border-radius: unit(3);
}
