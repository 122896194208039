@import "../../../index.module.scss";

.textInputContainer {
  position: relative;
  padding-bottom: unit(16);
  max-height: unit(58);

  :global {
    label {
      @include font-14;

      font-size: unit(14) !important;
    }
  }
}

.textInputContainerDisabled > div,
.disabled {
  opacity: var(--input-opacity-disabled);
}

.datePickerIcon {
  position: absolute;
  right: unit(6);
  top: 50%;
  @include flex-raw(center);
}
