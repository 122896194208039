@import "../../index.module.scss";

.modelContainer {
  align-self: center;

  :global {
    .rc-dialog-content {
      border-radius: 0;
      min-height: unit(124);
    }

    .rc-dialog-body {
      min-height: unit(124);
    }
  }
}

.confirmor {
  width: unit(343);
  padding: unit(24) unit(16);
  background-color: var(--color-white);
  height: fit-content;
  @include flex-column(space-between);
}

.title {
  width: 100%;
  text-align: center;
  color: var(--zui-color-gs-100);
  @include font-15;
}

.content {
  color: var(--zui-color-gs-90);
  line-height: 20px;
  padding: unit(16) 0;
  @include font-14;

  width: 100%;
}

.footer {
  width: 100%;
  padding-top: unit(16);
  @include flex-raw(space-between);
}

.btnContainer {
  width: calc((100% - unit(8)) / 2) !important;
  height: unit(32) !important;
}

.textCenter {
  text-align: center;
}
