@import "../../../../../index.module.scss";

.list {
  padding: 0 unit(16) unit(16) unit(16);
  @include font-14;

  color: var(--text-color-headline);
  max-height: unit(370);
  overflow-y: auto;
  background-color: var(--background-color-secondary);
  margin-top: unit(16);
}

.listWithoutPaddingBottom {
  padding-bottom: 0;
}

.siteSurveyCabinet {
  margin-top: 0;
}

.accessoryType {
  // padding-top: unit(12);
  // padding-left: unit(4);
}

.typeLabel {
  position: relative;
  padding: 0 unit(16);
  height: unit(44);
  @include flex-raw(center);

  justify-content: flex-start;
}

.typeLabel::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--background-color-dark-01);
}

.accessoryItem {
  // margin: 0 unit(16);
  // height: unit(40);
}

.accessoryItem:not(:first-child) {
  padding-top: unit(8);
}

.value {
  padding-left: unit(16);
  line-height: unit(24);
  word-break: break-all;
}

.accessoryProperties {
  padding: unit(16);
  background-color: var(--color-white);
}

.accessoryPropertiesOne {
  padding: 0 unit(16);
  min-height: unit(40);
  @include flex-raw(flex-start);

  .value {
    padding-left: 0;
  }
}

.label {
  color: var(--text-color-body);
}

/** Pc */
.accessoryPropertiesPc {
  @include pcContentViewContainer;
}

.accessoryItemPc {
  @include pcContentViewItem;
}

.cabinetClassName {
  justify-content: flex-start;
  gap: unit(8);
}
